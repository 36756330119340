export function checkingDeletedItem(prevArr, nextArr) {
    const currentPrev = prevArr.map((item) => `${item.period}`);
    const currentNext = nextArr.map((item) => `${item.period}`);
    const result = [];

    currentPrev.forEach((item) => {
        if (!currentNext.includes(item)) {
            result.push({ ratingPeriod: item });
        }
    });

    return result;
}
