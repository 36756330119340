import {
    Datagrid,
    List,
    SelectField,
    TextField,
    DateField,
} from 'ra-ui-materialui';
import * as React from 'react';
import { ReferenceField } from 'react-admin';

export const CompaniesList = (props) => {
    return (
        <List {...props} exporter={false} title="Компании">
            <Datagrid rowClick="edit">
                <TextField label="Название" source="name" />
                <ReferenceField
                    sortBy="industry"
                    label="Отрасль"
                    source="industry"
                    reference="industries"
                >
                    <TextField sortBy="_id" source="name" />
                </ReferenceField>
                <TextField label="Рейтинг" source="rating" />
                <SelectField
                    choices={[
                        { id: 'draft', name: 'Черновик' },
                        { id: 'published', name: 'Опубликована' },
                    ]}
                    source="publicationStatus"
                    label="Статус публикации"
                />
                <TextField source="country" label="Страна" />
                <DateField label="Дата создания" source="createdAt" />
            </Datagrid>
        </List>
    );
};
