import {
    Datagrid,
    List,
    NumberField,
    ReferenceField,
    SelectField,
    TextField,
} from 'ra-ui-materialui';

export const ProductList = (props) => (
    <List {...props} exporter={false} title="Продукты">
        <Datagrid rowClick="edit">
            <TextField label="Название" source="name" />
            <NumberField source="price" label="Цена в рублях" />
            <SelectField
                choices={[
                    { id: 'draft', name: 'Черновик' },
                    { id: 'published', name: 'Опубликован' },
                ]}
                source="status"
                label="Статус публикации"
            />
            <ReferenceField
                label="Категория"
                source="productCategory"
                reference="product-category"
                link="show"
            >
                <TextField source="name" />
            </ReferenceField>
        </Datagrid>
    </List>
);
