import { Edit, SelectInput, SimpleForm, TextInput } from 'ra-ui-materialui';
import { BooleanInput, ReferenceInput } from 'react-admin';
import * as React from 'react';
import styled from 'styled-components';

const PotentialWrapper = styled.p`
    font-family: 'Exo2';
`;

const CustomField = (props) => {
    return (
        <>
            <PotentialWrapper>Потенциал</PotentialWrapper>
            <BooleanInput source={props.source} />
        </>
    );
};

export const CommodityEdit = (props) => {
    return (
        <Edit mutationMode="pessimistic" {...props}>
            <SimpleForm>
                <TextInput label="Название" source="name" />
                <TextInput label="Валюта/ед. измерения" source="ticker" />
                <TextInput label="Тикер" source="symbol" />
                <CustomField source="growth_potential" />
                <ReferenceInput reference="sectors" source="sector_id">
                    <SelectInput label="Сектор" source="sector_id" />
                </ReferenceInput>
            </SimpleForm>
        </Edit>
    );
};
