import { Create, SimpleForm, TextInput } from 'ra-ui-materialui';

export const SectorCreate = (props) => {
    return (
        <Create title="Создание сектора" {...props}>
            <SimpleForm>
                <TextInput label="Название" source={'name'} />
            </SimpleForm>
        </Create>
    );
};
