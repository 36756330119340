import {
    Create,
    SelectInput,
    DateInput,
    SimpleForm,
    TextInput,
    ImageInput,
} from 'ra-ui-materialui';
import { ImageField } from 'react-admin';
import { required } from 'ra-core';

export const ReviewCreate = (props) => {
    return (
        <Create title="Создание отзыва" {...props}>
            <SimpleForm>
                <SelectInput
                    label="Рейтинг"
                    source="reviewRating"
                    choices={[
                        { id: '1', name: '1 звезда' },
                        { id: '2', name: '2 звезды' },
                        { id: '3', name: '3 звезды' },
                        { id: '4', name: '4 звезды' },
                        { id: '5', name: '5 звезд' },
                    ]}
                    validate={required()}
                />

                <TextInput
                    label="Текст отзыва"
                    source="text"
                    validate={required()}
                    fullWidth
                />

                <TextInput
                    label="Автор"
                    source="author"
                    validate={required()}
                />

                <DateInput
                    label="Дата отзыва"
                    source="date"
                    validate={required()}
                />

                <ImageInput
                    label="Логотип"
                    source="logo"
                    validate={required()}
                    fullWidth
                >
                    <ImageField />
                </ImageInput>

                <SelectInput
                    label="Статус публикации"
                    source="publicationStatus"
                    choices={[
                        { id: 'draft', name: 'Черновик' },
                        { id: 'published', name: 'Опубликована' },
                    ]}
                    validate={required()}
                />
            </SimpleForm>
        </Create>
    );
};
