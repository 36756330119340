import { Edit, SimpleForm, TextInput } from 'ra-ui-materialui';

import { NumberInput } from 'react-admin';

export const RequisitesEdit = (props) => {
    return (
        <Edit mutationMode="pessimistic" {...props}>
            <SimpleForm>
                <TextInput source="fullName" label="Полное название" />
                <TextInput source="shortName" label="Краткое название" />
                <NumberInput source="inn" label="ИНН" />
                <NumberInput source="kpp" label="КПП" />
                <NumberInput source="ogrn" label="ОГРН" />
                <TextInput source="legalAddress" label="Адрес" />
                <TextInput source="bankDetails" label="Банковские реквизиты" />
            </SimpleForm>
        </Edit>
    );
};
