import { Datagrid, List, TextField, UrlField } from 'ra-ui-materialui';
import { ReferenceField } from 'react-admin';
import { useState } from 'react';
import styled from 'styled-components';
import DragAndDropPositionSource from './dragAndDropPositionSource';

const Button = styled.button`
    border: 0;
    margin: 40px 0 0;
    padding: 20px 0;
    font-family: 'Exo2';
    font-size: 24px;
    font-weight: 600;
    cursor: pointer;
`;

export const PartnersList = (props) => {
    const [draggable, setDraggable] = useState(false);

    return (
        <>
            {!draggable ? (
                <List {...props} exporter={false} title="Продукты">
                    <Datagrid rowClick="edit">
                        <TextField label="id" source="id" />
                        <TextField label="Название" source="title" />
                        <UrlField label="Ссылка" source="link" />
                        <TextField label="Описание" source="description" />
                        <ReferenceField
                            reference="source-categories"
                            source="category"
                            label="Категория"
                        >
                            <TextField label="Категория" source="title" />
                        </ReferenceField>
                        <TextField label="Статус" source="status" />
                    </Datagrid>
                </List>
            ) : (
                <DragAndDropPositionSource />
            )}
            <Button onClick={() => setDraggable(!draggable)}>
                {draggable
                    ? 'Вернуться к списку партнеров'
                    : 'Перейти к редактированию позиции'}
            </Button>
        </>
    );
};
