import { Edit, SimpleForm, TextInput } from 'ra-ui-materialui';
import { useRecordContext } from 'react-admin';
import * as React from 'react';

const { REACT_APP_API_ENDPOINT } = process.env;

const CustomField = (props) => {
    const { source } = props;
    const record = useRecordContext(props);
    return (
        <a
            href={`${REACT_APP_API_ENDPOINT}/${record[source]}`}
            target="_blank"
            rel="noreferrer"
        >
            Документ
        </a>
    );
};

export const DocumentsContactsEdit = (props) => {
    return (
        <Edit mutationMode="pessimistic" {...props}>
            <SimpleForm>
                <TextInput source="title" label="Название" />
                <TextInput source="description" label="Описание" />
                <CustomField label="Документ" source="url" />
            </SimpleForm>
        </Edit>
    );
};
