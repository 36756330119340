import * as React from 'react';
import { FormDataConsumer, minLength, required } from 'ra-core';
import {
    BooleanInput,
    DateInput,
    Edit,
    FileField,
    FileInput,
    SelectInput,
    SimpleForm,
    TextInput,
    ImageInput,
} from 'ra-ui-materialui';
import { ImageField } from 'react-admin';

import { SlugifyUrlFromTitle } from './create-view';
import dataProvider from '../../data-providers/data-provider';
import DraftEditor from '../../content-editor';
import EditFormAutoSave from '../../helpers/editAndAutoSave';

const urlValidation = [required(), minLength(2), validateURL];

const PostTitle = ({ record }) => {
    return <span>Документ {record ? `"${record.title}"` : ''}</span>;
};

const CustomField = (props) => {
    return (
        <>
            <p>Отображать на главной странице</p>
            <BooleanInput required={required()} source={props.source} />
        </>
    );
};

export const DocumentEdit = (props) => {
    return (
        <Edit title={<PostTitle />} mutationMode="optimistic" {...props}>
            <SimpleForm>
                <EditFormAutoSave waitInterval={5000} />
                <SelectInput
                    defaultValue="document"
                    label="Тип"
                    source="type"
                    choices={[
                        { id: 'document', name: 'Документ' },
                        { id: 'article', name: 'Статья' },
                        { id: 'video', name: 'Видео' },
                    ]}
                />

                <FormDataConsumer>
                    {({ formData }) =>
                        formData.type === 'document' && (
                            <FileInput
                                accept="application/pdf"
                                label="PDF"
                                source="pdf"
                            >
                                <FileField source="url" title="PDF-файл" />
                            </FileInput>
                        )
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {({ formData }) =>
                        formData.type === 'video' && (
                            <SelectInput
                                defaultValue={
                                    formData.vimeoLink ? 'vimeo' : 'youtube'
                                }
                                label="Платформа видео"
                                source="videoPlatform"
                                choices={[
                                    { id: 'youtube', name: 'YouTube' },
                                    { id: 'vimeo', name: 'Vimeo' },
                                ]}
                            />
                        )
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {({ formData }) =>
                        formData.type === 'video' &&
                        formData.videoPlatform === 'vimeo' && (
                            <TextInput
                                label="Vimeo-ссылка"
                                source="vimeoLink"
                            />
                        )
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {({ formData }) =>
                        formData.type === 'video' &&
                        formData.videoPlatform === 'youtube' && (
                            <TextInput
                                label="Youtube-ссылка"
                                source="youtubeLink"
                            />
                        )
                    }
                </FormDataConsumer>

                <TextInput label="Название" source="title" fullWidth />

                <TextInput
                    fullWidth
                    label="Превью-текст"
                    multiline
                    source="preview"
                />

                <ImageInput
                    source="previewImage"
                    label="Превью-изображение"
                    fullWidth
                >
                    <ImageField />
                </ImageInput>

                <DraftEditor label="Контент" source="content" />

                <TextInput validate={urlValidation} label="URL" source="url" />
                <SlugifyUrlFromTitle />

                <SelectInput
                    label="Статус публикации"
                    source="status"
                    choices={[
                        { id: 'draft', name: 'Черновик' },
                        { id: 'published', name: 'Опубликован' },
                    ]}
                />

                <CustomField source="isVisibleOnMainPage" />

                <DateInput source="createdDate" label="Дата создания" />
            </SimpleForm>
        </Edit>
    );
};

async function validateURL(url, currentDocument) {
    const total = await dataProvider.getCount('document', { url });

    if (total >= 1 && url !== currentDocument.url) {
        return 'URL должен быть уникальным.';
    }

    return null;
}
