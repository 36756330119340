import {
    Datagrid,
    DateField,
    List,
    SelectField,
    TextField,
} from 'ra-ui-materialui';
import { ImageField, UrlField } from 'react-admin';

export const PublicationsList = (props) => {
    return (
        <List {...props} exporter={false} title="Отрасли">
            <Datagrid rowClick="edit">
                <TextField label="Превью" source="preview" />
                <TextField label="Статус" source="status" />
                <UrlField label="Ссылка" source="link" />
                <ImageField label="Логотип" source="logo" />
                <DateField label="Дата" source="publicationDate" />
                <SelectField
                    choices={[
                        { id: 'draft', name: 'Черновик' },
                        { id: 'published', name: 'Опубликована' },
                    ]}
                    source="publicationStatus"
                    label="Статус публикации"
                />
            </Datagrid>
        </List>
    );
};
