import { required } from 'ra-core';
import { SimpleForm, TextInput, Create } from 'ra-ui-materialui';

export const ProductCategoryCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput validate={required()} label="Название" source="name" />
        </SimpleForm>
    </Create>
);
