import { Datagrid, DateField, List, TextField } from 'ra-ui-materialui';
import { makeStyles } from '@material-ui/core/styles';
import * as React from 'react';
import { ImageField } from 'react-admin';

const useStyles = makeStyles({
    imageContainer: {
        '& img': {
            width: 160,
            maxHeight: 100,
            height: 100,
            margin: 0,
            objectFit: 'cover',
        },
    },
});

export const InvestmodulesList = (props) => {
    const classes = useStyles();

    return (
        <List {...props} exporter={false} title="Секторы" pagination={false}>
            <Datagrid rowClick="edit">
                <TextField label="ID" source="id" />
                <ImageField
                    label="Лого"
                    source="logo"
                    className={classes.imageContainer}
                />
                <TextField label="Название" source="title" />
                <TextField label="Опубликована" source="publicationStatus" />
                <TextField label="Статус" source="status" />
                <TextField label="Тип" source="type" />
                <DateField label="Дата запуска" source="startDate" />
                <DateField label="Дата создания" source="created" />
            </Datagrid>
        </List>
    );
};
