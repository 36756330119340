import { Edit, SimpleForm, TextInput } from 'ra-ui-materialui';
import * as React from 'react';

export const ResourceCategoryEdit = (props) => {
    return (
        <Edit mutationMode="optimistic" {...props}>
            <SimpleForm>
                <TextInput label="Название" source="name" fullWidth />
            </SimpleForm>
        </Edit>
    );
};
