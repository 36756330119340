import { Create, SelectInput, SimpleForm, TextInput } from 'ra-ui-materialui';

export const IndustryCreate = (props) => {
    return (
        <Create title="Создание отрасли" {...props}>
            <SimpleForm>
                <TextInput label="Название" source="name" />
                <SelectInput
                    choices={[
                        { id: 'draft', name: 'Черновик' },
                        { id: 'published', name: 'Опубликована' },
                    ]}
                    source="publicationStatus"
                    label="Статус публикации"
                />
            </SimpleForm>
        </Create>
    );
};
