import React, { useEffect, useState } from 'react';
import Sticky from 'sticky-js';

// Editor
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import embed from 'embed-video';

// UI
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { Labeled } from 'ra-ui-materialui';
import { useInput } from 'ra-core';
import dataProvider from '../data-providers/data-provider';
// import getImageComponent from './image';

function removeEmpty(obj) {
    return Object.fromEntries(
        Object.entries(obj).filter(([_, v]) => v != null)
    );
}

function DraftEditor({ ...props }) {
    const [editorState, setEditorState] = useState(EditorState.createEmpty());

    const {
        input: { name, onChange: reactFinalFormOnChange, ...rest },
    } = useInput(props);

    useEffect(() => {
        new Sticky('.sticky');

        const { blocks = [], entityMap = {} } =
            rest.value || props?.record?.content || {};
        if (blocks.length > 0) {
            const customEntityMap = removeEmpty(entityMap);
            const state = convertFromRaw({
                blocks,
                entityMap: customEntityMap,
            });
            setEditorState(EditorState.createWithContent(state));
        } else {
            setEditorState(EditorState.createEmpty());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props?.record?.content]);

    const onEditorStateChange = (editorState) => {
        setEditorState(editorState);
        const content = convertToRaw(editorState.getCurrentContent());
        reactFinalFormOnChange(content);
    };

    const uploadImage = async (file) => {
        try {
            const [{ url }] = await dataProvider.uploadFiles({ files: file });
            return { data: { link: url } };
        } catch (error) {
            console.error('error loading image', error);
            window.alert('Ошибка при загрузке картинки');
        }
    };

    return (
        <Labeled label={props.label ? props.label : ''}>
            <div
                style={{
                    border: '1px solid rgba(0, 0, 0, .1)',
                    minHeight: '600px',
                }}
            >
                <Editor
                    localization={{
                        locale: 'ru',
                    }}
                    editorState={editorState}
                    wrapperClassName="editor"
                    toolbarClassName="sticky"
                    stripPastedStyles
                    editorClassName="editor"
                    onEditorStateChange={onEditorStateChange}
                    toolbar={{
                        options: [
                            'inline',
                            'blockType',
                            'fontSize',
                            'fontFamily',
                            'list',
                            'textAlign',
                            'colorPicker',
                            'link',
                            'emoji',
                            'image',
                            'remove',
                            'history',
                        ],
                        fontFamily: {
                            options: ['Exo2'],
                        },
                        embedded: {
                            embedCallback: (link) => {
                                const detectedSrc =
                                    /<iframe.*? src="(.*?)"/.exec(embed(link));
                                return (detectedSrc && detectedSrc[1]) || link;
                            },
                        },
                        image: {
                            alt: { present: true, mandatory: true },
                            uploadEnabled: true,
                            uploadCallback: uploadImage,
                            previewImage: true,
                            alignmentEnabled: true,
                        },
                    }}
                />
            </div>
        </Labeled>
    );
}

// const getBlockRenderFunc = (config) => (block) => {
//     if (block.getType() === 'atomic') {
//         const contentState = config.editorState.getCurrentContent();
//         const entity = contentState.getEntity(block.getEntityAt(0));
//         if (entity && entity.type === 'IMAGE') {
//             return {
//                 component: getImageComponent(config),
//                 editable: false,
//             };
//         }
//     }
//     return undefined;
// };

export default DraftEditor;
