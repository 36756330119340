import { Datagrid, DateField, List, TextField } from 'ra-ui-materialui';
import * as React from 'react';
import { ImageField } from 'react-admin';

export const StrategiesList = (props) => {
    return (
        <List {...props} exporter={false} title="Секторы" pagination={false}>
            <Datagrid rowClick="edit">
                <TextField label="ID" source="id" />
                <ImageField source="logo" label="Лого" />
                <TextField label="Название" source="title" />
                <TextField label="Опубликована" source="publicationStatus" />
                <TextField label="Статус" source="status" />
                <TextField label="Тип" source="type" />
                <DateField source="created" label="Дата создания" />
            </Datagrid>
        </List>
    );
};
