import {
    Datagrid,
    DateField,
    List,
    SelectField,
    TextField,
} from 'ra-ui-materialui';

export const IndustriesList = (props) => {
    return (
        <List {...props} exporter={false} title="Отрасли">
            <Datagrid rowClick="edit">
                <TextField label="Название" source="name" />
                <DateField label="Последнее обновление" source="updateDate" />
                <SelectField
                    choices={[
                        { id: 'draft', name: 'Черновик' },
                        { id: 'published', name: 'Опубликована' },
                    ]}
                    source="publicationStatus"
                    label="Статус публикации"
                />
            </Datagrid>
        </List>
    );
};
