import { Edit, SimpleForm, TextInput } from 'ra-ui-materialui';

export const SectorEdit = (props) => {
    return (
        <Edit mutationMode="pessimistic" {...props}>
            <SimpleForm>
                <TextInput source={'name'} />
            </SimpleForm>
        </Edit>
    );
};
