import { Create, FileField, FileInput, SimpleForm } from 'ra-ui-materialui';

export const DocumentsContactsCreate = (props) => {
    return (
        <Create title="Создание компании" {...props}>
            <SimpleForm>
                <FileInput accept="application/pdf" label="PDF" source="file">
                    <FileField source="url" title="PDF-файл" />
                </FileInput>
            </SimpleForm>
        </Create>
    );
};
