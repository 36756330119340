import { required } from 'ra-core';
import { SimpleForm, TextInput, Edit } from 'ra-ui-materialui';

const PostTitle = ({ record }) => {
    return <span>Категория {record ? `"${record.name}"` : ''}</span>;
};

export const ProductCategoryEdit = (props) => (
    <Edit mutationMode="optimistic" title={<PostTitle />} {...props}>
        <SimpleForm>
            <TextInput validate={required()} label="Название" source="name" />
        </SimpleForm>
    </Edit>
);
