import {
    Datagrid,
    DateField,
    List,
    SelectField,
    TextField,
} from 'ra-ui-materialui';
import { ImageField } from 'react-admin';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(() => ({
    tableCellText: {
        maxWidth: 300,
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    tableCellImage: {
        '& img': {
            width: 160,
            maxHeight: 100,
            height: 100,
            margin: 0,
            objectFit: 'contain',
        },
    },
}));

export const ReviewsList = (props) => {
    const classes = useStyles();

    return (
        <List {...props} exporter={false} title="Отзывы">
            <Datagrid rowClick="edit">
                <TextField
                    label="Текст отзыва"
                    source="text"
                    cellClassName={classes.tableCellText}
                />
                <TextField label="Автор" source="author" />
                <DateField label="Дата отзыва" source="date" />

                <SelectField
                    label="Рейтинг"
                    source="reviewRating"
                    choices={[
                        { id: '1', name: '1 звезда' },
                        { id: '2', name: '2 звезды' },
                        { id: '3', name: '3 звезды' },
                        { id: '4', name: '4 звезды' },
                        { id: '5', name: '5 звезд' },
                    ]}
                />

                <ImageField
                    label="Логотип"
                    source="logo"
                    cellClassName={classes.tableCellImage}
                />

                <SelectField
                    label="Статус публикации"
                    source="publicationStatus"
                    choices={[
                        { id: 'draft', name: 'Черновик' },
                        { id: 'published', name: 'Опубликована' },
                    ]}
                />
            </Datagrid>
        </List>
    );
};
