import {
    ArrayInput,
    Create,
    SelectInput,
    SimpleForm,
    TextInput,
} from 'ra-ui-materialui';
import {
    AutocompleteInput,
    NumberInput,
    SimpleFormIterator,
} from 'react-admin';
import React from 'react';
import BackButton from '../../components/BackButton';
import { required } from 'ra-core';
import { setDateRange } from '../../helpers/setDateRange';

export const RatingIndustryCreate = (props) => {
    return (
        <>
            <BackButton />
            <Create
                title="Создание отрасли"
                {...props}
                mutationMode="pessimistic"
            >
                <SimpleForm>
                    <TextInput required label="Название" source="name" />
                    <SelectInput
                        isRequired
                        choices={[
                            { id: 'США', name: 'США' },
                            { id: 'РФ', name: 'РФ' },
                        ]}
                        source="country"
                        label="Страна"
                    />
                    <ArrayInput source="rating">
                        <SimpleFormIterator>
                            <AutocompleteInput
                                source="period"
                                label="Период"
                                validate={[required()]}
                                choices={setDateRange()}
                            />
                            <NumberInput
                                source="rating"
                                label="Рейтинг"
                                validate={[required()]}
                            />
                        </SimpleFormIterator>
                    </ArrayInput>
                </SimpleForm>
            </Create>
        </>
    );
};
