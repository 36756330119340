import {
    Datagrid,
    DateField,
    List,
    NumberField,
    TextField,
} from 'ra-ui-materialui';

export const InvestIdeasList = (props) => {
    return (
        <List {...props} exporter={false} title="Инвест идеи">
            <Datagrid rowClick="edit">
                <TextField label="Название" source="title" />
                <TextField label="Валюта" source="currency" />
                <TextField label="Тикер" source="ticker" />
                <TextField label="Статус" source="status" />
                <NumberField label="Стартовая цена" source="startPrice" />
                <NumberField label="Целевая цена" source="targetPrice" />
                <DateField label="Дата создания" source="created" />
            </Datagrid>
        </List>
    );
};
