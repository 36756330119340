import * as React from 'react';
import slugify from 'slugify';
import { minLength, required, FormDataConsumer } from 'ra-core';
import {
    BooleanInput,
    Create,
    SelectInput,
    SimpleForm,
    TextInput,
    FileField,
    FileInput,
    ImageInput,
} from 'ra-ui-materialui';
import { ImageField } from 'react-admin';
import { useFormState, Field, FormSpy } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import dataProvider from '../../data-providers/data-provider';
import DraftEditor from '../../content-editor';

const urlValidation = [required(), minLength(2), validateURL];

// https://erikras.com/blog/declarative-form-rules
export const SlugifyUrlFromTitle = ({
    titleFieldName = 'title',
    urlFieldName = 'url',
}) => {
    const formState = useFormState();

    return (
        <Field name={urlFieldName} subscription={{}}>
            {(
                // No subscription. We only use Field to get to the change function
                { input: { onChange } }
            ) => (
                <FormSpy subscription={{}}>
                    {() => (
                        <OnChange name={titleFieldName}>
                            {(value) => {
                                if (
                                    value &&
                                    !formState.touched.url &&
                                    !formState.initialValues.url
                                ) {
                                    const slugified = slugify(value);
                                    onChange(slugified);
                                }
                            }}
                        </OnChange>
                    )}
                </FormSpy>
            )}
        </Field>
    );
};

export const DocumentCreate = (props) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <SelectInput
                    defaultValue="document"
                    label="Тип"
                    source="type"
                    choices={[
                        { id: 'document', name: 'Документ' },
                        { id: 'article', name: 'Статья' },
                        { id: 'video', name: 'Видео' },
                    ]}
                />

                <FormDataConsumer>
                    {({ formData }) =>
                        formData.type === 'video' && (
                            <SelectInput
                                defaultValue={
                                    formData.vimeoLink ? 'vimeo' : 'youtube'
                                }
                                label="Платформа видео"
                                source="videoPlatform"
                                choices={[
                                    { id: 'youtube', name: 'YouTube' },
                                    { id: 'vimeo', name: 'Vimeo' },
                                ]}
                            />
                        )
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {({ formData }) =>
                        formData.type === 'video' &&
                        formData.videoPlatform === 'vimeo' && (
                            <TextInput
                                label="Vimeo-ссылка"
                                source="vimeoLink"
                            />
                        )
                    }
                </FormDataConsumer>

                <FormDataConsumer>
                    {({ formData }) =>
                        formData.type === 'video' &&
                        formData.videoPlatform === 'youtube' && (
                            <TextInput
                                label="Youtube-ссылка"
                                source="youtubeLink"
                            />
                        )
                    }
                </FormDataConsumer>

                <TextInput
                    validate={required()}
                    label="Название"
                    fullWidth
                    source="title"
                />

                <TextInput
                    fullWidth
                    validate={required()}
                    label="Превью-текст"
                    multiline
                    source="preview"
                />

                <ImageInput
                    source="previewImage"
                    label="Превью-изображение"
                    fullWidth
                >
                    <ImageField />
                </ImageInput>

                <DraftEditor label="Контент" source="content" />
                <SlugifyUrlFromTitle />
                <TextInput validate={urlValidation} label="URL" source="url" />

                <FileInput accept="application/pdf" label="PDF" source="pdf">
                    <FileField source="url" title="PDF-файл" />
                </FileInput>

                <SelectInput
                    defaultValue="draft"
                    label="Статус публикации"
                    source="status"
                    choices={[
                        { id: 'draft', name: 'Черновик' },
                        { id: 'published', name: 'Опубликован' },
                    ]}
                />

                <p>Отображать на главной странице</p>
                <BooleanInput
                    required={required()}
                    source="isVisibleOnMainPage"
                />
            </SimpleForm>
        </Create>
    );
};

async function validateURL(url) {
    const total = await dataProvider.getCount('document', { url });

    if (total >= 1) {
        return 'URL должен быть уникальным.';
    }

    return null;
}
