import * as React from 'react';
import { minLength, required } from 'ra-core';
import {
    BooleanInput,
    Create,
    SelectInput,
    SimpleForm,
    TextInput,
    ImageInput,
} from 'ra-ui-materialui';
import { ImageField } from 'react-admin';
import { useFormState, Field, FormSpy } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import slugify from 'slugify';

import dataProvider from '../../data-providers/data-provider';
import DraftEditor from '../../content-editor';

const urlValidation = [required(), minLength(2), validateURL];

export const SlugifyUrlFromTitle = ({
    titleFieldName = 'title',
    urlFieldName = 'url',
}) => {
    const formState = useFormState();

    return (
        <Field name={urlFieldName} subscription={{}}>
            {(
                // No subscription. We only use Field to get to the change function
                { input: { onChange } }
            ) => (
                <FormSpy subscription={{}}>
                    {() => (
                        <OnChange name={titleFieldName}>
                            {(value) => {
                                if (
                                    value &&
                                    !formState.touched.url &&
                                    !formState.initialValues.url
                                ) {
                                    const slugified = slugify(value);
                                    onChange(slugified);
                                }
                            }}
                        </OnChange>
                    )}
                </FormSpy>
            )}
        </Field>
    );
};

export const NewsCreate = (props) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput
                    validate={required()}
                    label="Название"
                    fullWidth
                    source="title"
                />
                <TextInput
                    fullWidth
                    validate={required()}
                    label="Превью-текст"
                    multiline
                    source="preview"
                />
                <ImageInput
                    source="previewImage"
                    label="Превью-изображение"
                    fullWidth
                >
                    <ImageField />
                </ImageInput>
                <DraftEditor label="Контент" source="content" />
                <SlugifyUrlFromTitle />
                <TextInput validate={urlValidation} label="URL" source="url" />
                <SelectInput
                    defaultValue="draft"
                    label="Статус публикации"
                    source="status"
                    choices={[
                        { id: 'draft', name: 'Черновик' },
                        { id: 'published', name: 'Опубликован' },
                    ]}
                />
                <p>Отобржать на главной странице</p>
                <BooleanInput
                    required={required()}
                    source="isVisibleOnMainPage"
                />
            </SimpleForm>
        </Create>
    );
};

async function validateURL(url) {
    const total = await dataProvider.getCount('document', { url });

    if (total >= 1) {
        return 'URL должен быть уникальным.';
    }

    return null;
}
