import { Datagrid, List, TextField } from 'ra-ui-materialui';
import * as React from 'react';
import { useRecordContext } from 'react-admin';

const { REACT_APP_API_ENDPOINT } = process.env;

const CustomField = (props) => {
    const { source } = props;
    const record = useRecordContext(props);
    return (
        <a
            href={`${REACT_APP_API_ENDPOINT}/${record[source]}`}
            target="_blank"
            rel="noreferrer"
        >
            Документ
        </a>
    );
};

export const DocumentsContactsList = (props) => {
    return (
        <List {...props} exporter={false} title="Документы" pagination={false}>
            <Datagrid rowClick="edit">
                <TextField label="id" source="id" />
                <TextField label="Описание" source="description" />
                <TextField label="Заголовок" source="title" />
                <CustomField label="Документ" source="url" />
            </Datagrid>
        </List>
    );
};
