import { Datagrid, List, TextField } from 'ra-ui-materialui';

export const PartnersCategoriesList = (props) => (
    <List {...props} exporter={false} title="Продукты">
        <Datagrid rowClick="edit">
            <TextField label="id" source="id" />
            <TextField label="Название" source="title" />
            <TextField label="Описание" source="description" />
        </Datagrid>
    </List>
);
