import { useEffect, useRef, useState } from 'react';
import { useFormState } from 'react-final-form';
import authAxios from './axios';
import Swal from 'sweetalert2';
import styled from 'styled-components';
import errorDetector from './errorDetector';

const AutoSaveLoadWrapper = styled.div`
    background-color: #0046cc;
    padding: 10px;
    border-radius: 8px;
    color: #fff;
    position: fixed;
    z-index: 100;
    opacity: ${(props) => (props.show ? '1' : '0')};
    bottom: 40px;
    right: 20px;
    transition: opacity 0.2s linear;
`;

const custonUploadFiles = async ({ files }) => {
    const filesToUpload =
        files.length && files.length > 1 ? [...files] : [files].filter(Boolean);
    const body = new FormData();

    filesToUpload.forEach((file) => body.append('files', file, file.name));

    try {
        const { data } = await authAxios.post(`/api/file/upload`, body, {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        return data;
    } catch (e) {
        console.error(e);

        return Swal.fire(
            errorDetector(
                e.response.status,
                e.response.statusText,
                e.response.message
            )
        );
    }
};

const customUpdate = async (values, id) => {
    const filesToUpload = Object.entries(values)
        .filter(([key, value]) => value.rawFile)
        .map(([key, value]) => ({ field: key, file: value.rawFile }));

    const extraData = {};

    if (filesToUpload.length) {
        const uploadedFiles = await custonUploadFiles({
            files:
                filesToUpload.length > 1
                    ? filesToUpload.map((entry) => entry.file)
                    : filesToUpload[0].file,
        });

        filesToUpload.forEach((fileToUpload, index) => {
            extraData[fileToUpload.field] = uploadedFiles[index].id;
        });
    }

    const uploadedFileIds = Object.entries(values)
        .filter(([key, value]) => value.fileName)
        .map(([key, value]) => ({ [key]: value.id }));

    // для связанных сущностей, которые имеют свой id
    const relatedEntities = Object.entries(values)
        .filter(([key, value]) => value.id)
        .map(([key, value]) => ({ [key]: value.id }));

    const payloadData = {
        ...values,
        ...Object.assign({}, ...uploadedFileIds),
        ...Object.assign({}, ...relatedEntities),
        ...extraData,
    };
    try {
        // eslint-disable-next-line no-unused-vars
        const { data } = await authAxios.patch(
            `/api/document/${id}`,
            payloadData
        );
        return true;
    } catch (e) {
        return Swal.fire(
            errorDetector(
                e.response.status,
                e.response.statusText,
                'document',
                e.response.message
            )
        );
    }
};

let timeOut = setTimeout(() => {}, 1000);

const EditFormAutoSave = ({ waitInterval }) => {
    const [show, setShow] = useState(false);
    const { dirty, valid, values } = useFormState({
        subscription: {
            dirty: true,
            valid: true,
            values: true,
        },
    });
    const shouldSubmitRef = useRef();

    const customLol = async () => {
        const wasUpdateId = window.location.hash.split('/')[2];
        if (wasUpdateId) {
            const wasUpdate = await customUpdate(
                values,
                window.location.hash.split('/')[2]
            );
            if (wasUpdate) setShow(true);
        }
    };

    useEffect(() => {
        shouldSubmitRef.current = dirty && valid;
    }, [dirty, valid]);

    useEffect(() => {
        setShow(false);
        clearTimeout(timeOut);
        timeOut = setTimeout(() => {
            customLol();
        }, waitInterval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dirty, valid, values]);

    return <AutoSaveLoadWrapper show={show}>Сохранено!</AutoSaveLoadWrapper>;
};

export default EditFormAutoSave;
