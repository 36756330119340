import {
    ArrayInput,
    Edit,
    SelectInput,
    SimpleForm,
    TextInput,
} from 'ra-ui-materialui';
import {
    NumberInput,
    SimpleFormIterator,
    AutocompleteInput,
} from 'react-admin';
import BackButton from '../../components/BackButton';
import { required } from 'ra-core';
import { setDateRange } from '../../helpers/setDateRange';

const PageTitle = ({ record }) => {
    return <span>Отрасль {record ? `"${record.name}"` : ''}</span>;
};

export const RatingIndustryEdit = (props) => {
    return (
        <>
            <BackButton />
            <Edit title={<PageTitle />} mutationMode="pessimistic" {...props}>
                <SimpleForm>
                    <TextInput label="Название" source="name" />
                    <SelectInput
                        choices={[
                            { id: 'США', name: 'США' },
                            { id: 'РФ', name: 'РФ' },
                        ]}
                        source="country"
                        label="Страна"
                    />
                    <ArrayInput label="Рейтинг" source="newRating">
                        <SimpleFormIterator>
                            <AutocompleteInput
                                source="period"
                                label="Период"
                                validate={[required()]}
                                choices={setDateRange()}
                            />
                            <NumberInput
                                min={-100}
                                max={100}
                                isRequired
                                source="rating"
                                label="Рейтинг"
                                validate={[required()]}
                            />
                        </SimpleFormIterator>
                    </ArrayInput>
                    <ArrayInput label="" source="rating">
                        <SimpleFormIterator disableAdd>
                            <AutocompleteInput
                                source="period"
                                label="Период"
                                choices={setDateRange()}
                            />
                            <NumberInput
                                isRequired
                                source="rating"
                                label="Рейтинг"
                            />
                        </SimpleFormIterator>
                    </ArrayInput>
                </SimpleForm>
            </Edit>
        </>
    );
};
