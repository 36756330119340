import {
    Edit,
    ImageInput,
    SimpleForm,
    TextInput,
    SelectInput,
    ArrayInput,
} from 'ra-ui-materialui';
import {
    ImageField,
    NumberInput,
    ReferenceInput,
    SimpleFormIterator,
} from 'react-admin';
import DraftEditor from '../../content-editor';

export const StrategyEdit = (props) => {
    return (
        <Edit title="Редактирование стратегии" {...props}>
            <SimpleForm>
                <TextInput label="Название" source="title" />
                <ImageInput source="logo" label="Лого">
                    <ImageField />
                </ImageInput>
                <TextInput label="Описание" source="description" />
                <SelectInput
                    source="publicationStatus"
                    label="Статус публикации"
                    choices={[
                        { id: 'published', name: 'Опубликована' },
                        { id: 'draft', name: 'Черновик' },
                    ]}
                />
                <SelectInput
                    source="status"
                    label="Статус"
                    choices={[
                        { id: 'Актуальная', name: 'Актуальная' },
                        { id: 'Исполненная', name: 'Исполненная' },
                    ]}
                />
                <SelectInput
                    source="type"
                    label="Тип стратегии"
                    choices={[
                        { id: 'Сбалансированная', name: 'Сбалансированная' },
                        { id: 'Консервативная', name: 'Консервативная' },
                        { id: 'Агрессивная', name: 'Агрессивная' },
                    ]}
                />
                <NumberInput source="startVal" label="Стартовая цена" />
                <NumberInput
                    source="minimalAmount"
                    label="Минимальная сумма стратегии"
                />
                <NumberInput
                    source="potentialProfitability"
                    label="Планируемая доходность"
                />
                <NumberInput
                    source="plannedInvestmentPeriod"
                    label="Планируемый период инвестирования (в днях)"
                />
                <DraftEditor source="content" label="Контент" />
                <ArrayInput
                    source="strategyStructure"
                    label="Структура стратегии"
                >
                    <SimpleFormIterator>
                        <TextInput label="Название" source="title" />
                        <NumberInput label="Проценты" source="percent" />
                    </SimpleFormIterator>
                </ArrayInput>
                <ArrayInput
                    source="strategyTools"
                    label="Инструменты стратегии"
                >
                    <SimpleFormIterator>
                        <ReferenceInput
                            label="id"
                            reference="strategy-tools"
                            source="id"
                            perPage={false}
                        >
                            <SelectInput source="id" optionText="title" />
                        </ReferenceInput>
                        <NumberInput label="Amount" source="amount" />
                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleForm>
        </Edit>
    );
};
