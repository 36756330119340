import axios from 'axios';
import authAxios from '../helpers/axios';

const { REACT_APP_API_ENDPOINT: API_ENDPOINT = 'http://localhost:4000' } =
    process.env;

const authProvider = {
    // authentication
    async login({ username, password }) {
        const {
            data: { access_token },
        } = await axios.post(`${API_ENDPOINT}/api/auth/login`, {
            email: username,
            password,
        });
        localStorage.setItem('auth', access_token);
        return Promise.resolve();
    },
    checkError: (error) => {
        if (error.message.includes(401)) {
            localStorage.removeItem('auth');
            Promise.reject();
        }
    },
    checkAuth: (params) => {
        return localStorage.getItem('auth')
            ? Promise.resolve()
            : Promise.reject();
    },
    logout: () => {
        localStorage.removeItem('auth');
        return Promise.resolve();
    },
    async getIdentity() {
        const { data: user } = await authAxios.get(
            `${API_ENDPOINT}/api/auth/me`
        );
        return { username: user.email };
    },
    // authorization
    getPermissions: () => Promise.resolve(),
};

export default authProvider;
