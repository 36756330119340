import { BooleanField, Datagrid, List, TextField } from 'ra-ui-materialui';
import * as React from 'react';
import { ReferenceField } from 'react-admin';

export const CommodityList = (props) => {
    return (
        <List {...props} exporter={false} title="Секторы" pagination={false}>
            <Datagrid rowClick="edit">
                <TextField label="ID" source="id" />
                <TextField label="Название" source="name" />
                <BooleanField label="потенциал" source="growth_potential" />
                <TextField label="Валюта/ед. измерения" source="ticker" />
                <TextField label="Тикер" source="symbol" />
                <TextField label="Цена" source="current_price" />
                <TextField
                    label="Изменение за месяц"
                    source="monthly_price_change"
                />
                <TextField
                    label="Изменение за год"
                    source="yearly_price_change"
                />
                <ReferenceField
                    label="Сектор"
                    source="sector_id._id"
                    reference="sectors"
                >
                    <TextField source="name" />
                </ReferenceField>
            </Datagrid>
        </List>
    );
};
