import { Datagrid, List, TextField } from 'ra-ui-materialui';

export const ResourceCategoryList = (props) => {
    return (
        <List {...props} exporter={false} title="Новости">
            <Datagrid rowClick="edit">
                <TextField label="id" source="id" />
                <TextField label="Название" source="name" />
            </Datagrid>
        </List>
    );
};
