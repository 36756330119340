import { List } from 'ra-ui-materialui';
import * as React from 'react';
import { useEffect } from 'react';
import authAxios from '../../../helpers/axios';

export const RequisitesList = (props) => {
    useEffect(() => {
        const linkTo = async () => {
            const { data } = await authAxios.get('/api/about');
            window.location = `${window.location.href}/${data.id}`;
        };
        linkTo();
    });

    return (
        <List {...props} exporter={false} title="Реквизиты" pagination={false}>
            <div>.</div>
        </List>
    );
};
