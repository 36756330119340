import axios from 'axios';

const { REACT_APP_API_ENDPOINT: API_ENDPOINT = 'http://127.0.0.1:4000' } =
    process.env;

const authAxios = axios.create({
    baseURL: API_ENDPOINT,
});

authAxios.interceptors.request.use((config) => {
    const token = localStorage.getItem('auth');
    config.headers.Authorization = `Bearer ${token}`;
    return config;
});

authAxios.interceptors.response.use(
    (response) => response,
    async (error) => {
        const errorStatus = error?.response?.status ?? null;

        if (errorStatus === 401) {
            try {
                const refreshToken =
                    window.localStorage.getItem('refreshToken');
                const { data } = await axios.post(
                    `${API_ENDPOINT}/api/auth/refresh`,
                    {
                        refreshToken,
                    }
                );

                const { access_token, refreshToken: receivedRefreshToken } =
                    data;

                window.localStorage.setItem('auth', access_token);
                window.localStorage.setItem(
                    'refreshToken',
                    receivedRefreshToken
                );
                return null;
            } catch (e) {
                console.error(e);
                window.localStorage.removeItem('auth');
                window.localStorage.removeItem('refreshToken');
                window.location.reload();
            }
        } else {
            return Promise.reject(error);
        }
    }
);

export default authAxios;
