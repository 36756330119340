import {
    Edit,
    ImageInput,
    SimpleForm,
    TextInput,
    SelectInput,
    ArrayInput,
    DateInput,
} from 'ra-ui-materialui';
import {
    ImageField,
    NumberInput,
    ReferenceInput,
    SimpleFormIterator,
} from 'react-admin';
import DraftEditor from '../../content-editor';
// import { maxValue } from 'ra-core';

export const InvestmoduleEdit = (props) => {
    // const date = new Date();
    // const currentDate = date.toISOString().slice(0, 10); // YYYY-MM-DD

    return (
        <Edit title="Редактирование инвестиционного модуля" {...props}>
            <SimpleForm>
                <TextInput label="Название" source="title" />
                <ImageInput source="logo" label="Лого">
                    <ImageField />
                </ImageInput>
                <TextInput label="Описание" source="description" fullWidth />
                <SelectInput
                    source="publicationStatus"
                    label="Статус публикации"
                    choices={[
                        { id: 'published', name: 'Опубликована' },
                        { id: 'draft', name: 'Черновик' },
                    ]}
                />
                <DateInput
                    source="startDate"
                    label="Дата запуска"
                    // validate={maxValue(currentDate)}
                    disabled
                />
                <SelectInput
                    label="Валюта"
                    source="currency"
                    choices={[
                        { id: 'USD', name: 'USD' },
                        { id: 'RUB', name: 'RUB' },
                    ]}
                />

                <SelectInput
                    source="status"
                    label="Статус"
                    choices={[
                        { id: 'В работе', name: 'В работе' },
                        { id: 'Закрыт', name: 'Закрыт' },
                    ]}
                />
                <NumberInput
                    source="minimalAmount"
                    label="Минимальная сумма модуля"
                />
                <NumberInput
                    source="potentialProfitability"
                    label="Планируемая доходность"
                />
                <NumberInput
                    source="plannedInvestmentPeriod"
                    label="Планируемый период инвестирования (в днях)"
                />
                <DraftEditor source="content" label="Контент" />
                <ArrayInput
                    source="investmoduleTools"
                    label="Инструменты инвестиционного модуля"
                >
                    <SimpleFormIterator>
                        <ReferenceInput
                            label="Название инструмента"
                            reference="investmodule-tools"
                            source="id"
                            perPage={false}
                        >
                            <SelectInput source="id" optionText="title" />
                        </ReferenceInput>

                        <NumberInput label="Количество" source="amount" />
                        <SelectInput
                            source="toolStatus"
                            label="Статус инструмента"
                            choices={[
                                { id: 'В работе', name: 'В работе' },
                                { id: 'Закрыт', name: 'Закрыт' },
                            ]}
                        />
                        <TextInput
                            source="toolStartPrice"
                            label="Стартовая цена инструмента"
                        />
                        <TextInput
                            source="toolTargetPrice"
                            label="Целевая цена инструмента"
                        />
                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleForm>
        </Edit>
    );
};
