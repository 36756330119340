import React, { useEffect, useState } from 'react';
import ReactDragListView from 'react-drag-listview';
import authAxios from '../../helpers/axios';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import errorDetector from '../../helpers/errorDetector';

const MainWrapper = styled.div`
    width: 60%;
    margin: 0 auto;
`;

const MainTitle = styled.div`
    max-width: 100%;
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    padding: 0 80px;
    align-items: center;
`;

const Ul = styled.ul`
    width: 100%;
    margin: 0;
    list-style: none;
    padding: 0;
`;

const Li = styled.li`
    box-shadow: 0 0 1px 0 #000;
`;

const DragWrapper = styled.a`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 0 1px 0 #000;
    padding: 5px 70px;
    position: relative;
`;

const LiName = styled.p`
    width: 200px;
`;

const LiNewNumber = styled.p`
    width: 40px;
    text-align: center;
    position: relative;
    left: -40px;
`;

const LiOldNumber = styled.p`
    width: 40px;
    text-align: center;
    position: relative;
    left: -130px;
`;

const ButtonWrapper = styled.div`
    padding: 40px 0 0;
`;

const Button = styled.button`
    border: 0;
    border-radius: 4px;
    padding: 10px 20px;
    margin-right: 20px;
    background-color: #0acdec;
    cursor: pointer;
`;

const LinkToDrag = styled.div`
    line-height: 50px;
    font-weight: bold;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    cursor: pointer;

    &:hover {
        color: blue;
    }
`;

export default function DragDropPosition() {
    const [dataRus, setDataRus] = useState([]);
    const [dataUs, setDataUs] = useState([]);
    const [current, setCurrent] = useState(true);
    const [currentData, setCurrentData] = useState([]);

    useEffect(() => {
        async function loadData() {
            const { data: ruData } = await authAxios.get(
                '/api/rating-industry/info?countryName=РФ'
            );
            const { data: usData } = await authAxios.get(
                '/api/rating-industry/info?countryName=США'
            );
            setDataRus(ruData);
            setDataUs(usData);
            setCurrentData(ruData);
        }
        loadData();
    }, []);

    useEffect(() => {
        current ? setCurrentData(dataRus) : setCurrentData(dataUs);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [current]);

    const dragProps = {
        onDragEnd(fromIndex, toIndex) {
            const dataForDrag = [...currentData];
            const item = dataForDrag.splice(fromIndex, 1)[0];
            dataForDrag.splice(toIndex, 0, item);
            setCurrentData(dataForDrag);
        },
        nodeSelector: 'li',
        handleSelector: 'a',
    };

    const onSubmit = async () => {
        const dataForAxios = currentData.map((item) => {
            return { id: item.id, name: item.name };
        });
        try {
            // eslint-disable-next-line no-unused-vars
            const { data } = await authAxios.patch(
                '/api/rating-industry/position',
                dataForAxios
            );
            await Swal.fire({
                title: 'Спасибо',
                html: 'Мы все получили!',
                icon: 'success',
            });
        } catch (e) {
            return Swal.fire(
                errorDetector(
                    e.response.status,
                    e.response.statusText,
                    'rating-industry',
                    e.response.message
                )
            );
        }
    };
    return (
        <MainWrapper>
            <MainTitle>
                <p>Имя</p>
                <p>Новая Позиция</p>
                <p>Старая Позиция</p>
            </MainTitle>
            <ReactDragListView {...dragProps}>
                <Ul>
                    {currentData.map((item, index) => {
                        return (
                            <Li key={item.name}>
                                <DragWrapper>
                                    <LinkToDrag>|||</LinkToDrag>
                                    <LiName style={{ margin: '0 0 0 10px' }}>
                                        {item.name}
                                    </LiName>
                                    <LiOldNumber>{index + 1}</LiOldNumber>
                                    <LiNewNumber>
                                        {item.position + 1}
                                    </LiNewNumber>
                                </DragWrapper>
                            </Li>
                        );
                    })}
                </Ul>
            </ReactDragListView>
            <ButtonWrapper>
                <Button onClick={onSubmit}>Сохранить</Button>
                <Button onClick={() => setCurrent(!current)}>
                    Показать {current ? 'США' : 'РФ'}
                </Button>
            </ButtonWrapper>
        </MainWrapper>
    );
}
