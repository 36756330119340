import authAxios from '../helpers/axios';
import Swal from 'sweetalert2';
import errorDetector from '../helpers/errorDetector';
import { checkingDeletedItem } from '../helpers/chekingDeletedItem';
import checkSourcePosition from '../helpers/checkSourcePosition';

// ковертирует из формата react-admin в api-query-params
// https://www.npmjs.com/package/api-query-params

function convertReactAdminParams(reactAdminParams, resourceName = '') {
    let params = {
        sort: '',
    };

    if (reactAdminParams.pagination) {
        const limit = reactAdminParams.pagination.perPage;
        const skip = (reactAdminParams.pagination.page - 1) * limit;
        params.limit = limit;
        params.skip = skip;
    }

    if (reactAdminParams.sort) {
        const sortString = `${
            reactAdminParams.sort.order === 'DESC' ? '-' : ''
        }${reactAdminParams.sort.field}`;
        params.sort = params.sort += sortString;
    }

    if (reactAdminParams.filter) {
        params = {
            ...params,
            ...reactAdminParams.filter,
        };
    }
    return params;
}

const dataProvider = {
    async getCount(resource, params) {
        if (resource === 'news') {
            try {
                const { data: total } = await authAxios(
                    `/api/document/count?type=news`
                );
                return total;
            } catch (e) {
                console.log('Error count', { ...e });
                return null;
            }
        } else if (resource === 'document') {
            try {
                const { data: total } = await authAxios(
                    `/api/document/count?type!=news`,
                    {
                        params,
                    }
                );
                return total;
            } catch (e) {
                console.log('Error count', { ...e });
                return null;
            }
        } else if (!params.url) {
            try {
                const { data: total } = await authAxios(
                    `/api/${resource}/count`,
                    {
                        params,
                    }
                );
                return total?.count ? total.count : total;
            } catch (e) {
                console.log('Error count', { ...e });
                return null;
            }
        }
    },
    async getList(resource, params) {
        if (resource === 'about') {
            try {
                const { data } = await authAxios.get(`/api/${resource}`);

                return { data: [data], total: 1 };
            } catch (e) {
                if (e.response.status !== 401) {
                    return Swal.fire(
                        errorDetector(
                            e.response.status,
                            e.response.statusText,
                            resource,
                            e.response.message
                        )
                    );
                }
            }
        } else if (resource === 'news') {
            try {
                const { data: total } = await authAxios(
                    `/api/document?type=news`
                );
                return { data: total, total: 1 };
            } catch (e) {
                console.log('Error count', { ...e });
                return null;
            }
        } else if (resource === 'aboutFile') {
            try {
                const { data } = await authAxios.get(`/api/about/file`);

                return { data, total: 1 };
            } catch (e) {
                if (e.response.status !== 401) {
                    return Swal.fire(
                        errorDetector(
                            e.response.status,
                            e.response.statusText,
                            resource,
                            e.response.message
                        )
                    );
                }
            }
        } else if (resource === 'rating-industry') {
            try {
                const { data: rusRat } = await authAxios.get(
                    `/api/${resource}/info?countryName=РФ`
                );
                const { data: usRat } = await authAxios.get(
                    `/api/${resource}/info?countryName=США`
                );
                return { data: [...rusRat, ...usRat], total: 1 };
            } catch (e) {
                if (e.response.status !== 401) {
                    return Swal.fire(
                        errorDetector(
                            e.response.status,
                            e.response.statusText,
                            resource,
                            e.response.message
                        )
                    );
                }
            }
        } else if (resource === 'sectors' || resource === 'commodities') {
            const paramsByConvert = convertReactAdminParams(params, resource);
            const queryString = [];
            if (paramsByConvert.sector) {
                queryString.push(`sector=${paramsByConvert.sector}`);
            }
            const finalQueryString = queryString.length
                ? `?${queryString.join('&')}`
                : '';
            try {
                const { data } = await authAxios.get(
                    `/api/${resource}${finalQueryString}`
                );
                const newData = data.map((item) => ({ ...item, id: item._id }));

                return { data: newData, total: null };
            } catch (e) {
                if (e.response.status !== 401) {
                    return Swal.fire(
                        errorDetector(
                            e.response.status,
                            e.response.statusText,
                            resource,
                            e.response.message
                        )
                    );
                }
            }
        } else if (resource === 'document') {
            try {
                const { data } = await authAxios.get(
                    `/api/${resource}?type!=news`,
                    {
                        params: convertReactAdminParams(params, resource),
                    }
                );
                const total = await this.getCount(resource, params.filter);

                return { data, total: total };
            } catch (e) {
                if (e.response.status !== 401) {
                    return Swal.fire(
                        errorDetector(
                            e.response.status,
                            e.response.statusText,
                            resource,
                            e.response.message
                        )
                    );
                }
            }
        } else if (resource === 'commodity-resource') {
            try {
                const { data } = await authAxios.get(`/api/${resource}`, {
                    params: convertReactAdminParams(params, resource),
                });
                const result = [];

                Object.keys(data).forEach((item) => {
                    data[item].forEach((com) => {
                        result.push({ ...com, category: item });
                    });
                });
                return { data: result, total: null };
            } catch (e) {
                if (e.response.status !== 401) {
                    return Swal.fire(
                        errorDetector(
                            e.response.status,
                            e.response.statusText,
                            resource,
                            e.response.message
                        )
                    );
                }
            }
        } else if (resource === 'commodity-country') {
            try {
                const { data } = await authAxios.get(`/api/${resource}`);
                const result = data.map((item, index) => ({
                    ...item,
                    id: index,
                }));
                return { data: result, total: null };
            } catch (e) {
                if (e.response.status !== 401) {
                    return Swal.fire(
                        errorDetector(
                            e.response.status,
                            e.response.statusText,
                            resource,
                            e.response.message
                        )
                    );
                }
            }
        } else {
            try {
                const { data } = await authAxios.get(`/api/${resource}`, {
                    params: convertReactAdminParams(params, resource),
                });
                const total = await this.getCount(resource, params.filter);

                return { data, total: total };
            } catch (e) {
                if (e.response.status !== 401) {
                    return Swal.fire(
                        errorDetector(
                            e.response.status,
                            e.response.statusText,
                            resource,
                            e.response.message
                        )
                    );
                }
            }
        }
    },
    async getOne(resource, params) {
        if (resource === 'about') {
            try {
                const { data } = await authAxios.get(`/api/${resource}`);
                return { data };
            } catch (e) {
                if (e.response.status !== 401) {
                    return Swal.fire(
                        errorDetector(
                            e.response.status,
                            e.response.statusText,
                            resource,
                            e.response.message
                        )
                    );
                }
            }
        } else if (resource === 'investideas') {
            try {
                const { data } = await authAxios.get(
                    `/api/${resource}/cms/${params.id}`
                );
                return { data };
            } catch (e) {
                if (e.response.status !== 401) {
                    return Swal.fire(
                        errorDetector(
                            e.response.status,
                            e.response.statusText,
                            resource,
                            e.response.message
                        )
                    );
                }
            }
        } else if (resource === 'sources') {
            try {
                const { data } = await authAxios.get(`/api/${resource}`);
                const result = data.find((item) => item.id === params.id);
                return { data: result[0] };
            } catch (e) {
                if (e.response.status !== 401) {
                    return Swal.fire(
                        errorDetector(
                            e.response.status,
                            e.response.statusText,
                            resource,
                            e.response.message
                        )
                    );
                }
            }
        } else if (resource === 'news') {
            try {
                const { data } = await authAxios.get(
                    `/api/document/${params.id}`
                );
                return { data };
            } catch (e) {
                if (e.response.status !== 401) {
                    return Swal.fire(
                        errorDetector(
                            e.response.status,
                            e.response.statusText,
                            resource,
                            e.response.message
                        )
                    );
                }
            }
        } else if (resource === 'source-categories') {
            try {
                const { data } = await authAxios.get(`/api/${resource}`);
                const result = data.find((item) => item.id === params.id);
                return { data: result[0] };
            } catch (e) {
                if (e.response.status !== 401) {
                    return Swal.fire(
                        errorDetector(
                            e.response.status,
                            e.response.statusText,
                            resource,
                            e.response.message
                        )
                    );
                }
            }
        } else if (resource === 'aboutFile') {
            try {
                const { data } = await authAxios.get(`/api/about/file`);
                const result = data.find((item) => item.id === params.id);
                return { data: result[0] };
            } catch (e) {
                if (e.response.status !== 401) {
                    return Swal.fire(
                        errorDetector(
                            e.response.status,
                            e.response.statusText,
                            resource,
                            e.response.message
                        )
                    );
                }
            }
        } else if (resource === 'sectors' || resource === 'commodities') {
            try {
                const { data } = await authAxios.get(
                    `/api/${resource}/${params.id}`
                );
                const newData = { ...data, id: data._id };
                return { data: newData };
            } catch (e) {
                if (e.response.status !== 401) {
                    return Swal.fire(
                        errorDetector(
                            e.response.status,
                            e.response.statusText,
                            resource,
                            e.response.message
                        )
                    );
                }
            }
        } else if (resource === 'rating-industry') {
            try {
                const { data: rusRat } = await authAxios.get(
                    `/api/${resource}/info?countryName=РФ`
                );
                const { data: usRat } = await authAxios.get(
                    `/api/${resource}/info?countryName=США`
                );
                const allRatings = [...rusRat, ...usRat].find(
                    (item) => item.id === params.id
                );
                return { data: allRatings };
            } catch (e) {
                if (e.response.status !== 401) {
                    return Swal.fire(
                        errorDetector(
                            e.response.status,
                            e.response.statusText,
                            resource,
                            e.response.message
                        )
                    );
                }
            }
        } else if (resource === 'commodity-resource') {
            const { data: countries } = await this.getList(
                'commodity-country',
                {}
            );
            try {
                const { data: answer } = await authAxios.get(
                    `/api/${resource}/${params.id}`
                );
                const result = {
                    id: answer.id,
                    name: answer.name,
                    icon: answer.icon,
                    resourceCategoryId: answer.resourceCategoryId,
                    data: answer.data.map((item) => {
                        return {
                            country_name: countries.find(
                                (country) => country.name === item.country.name
                            ).id,
                            companies: item.companies.map((item) => {
                                return { company: item };
                            }),
                        };
                    }),
                };
                return { data: result };
            } catch (e) {
                if (e.response.status !== 401) {
                    return Swal.fire(
                        errorDetector(
                            e.response.status,
                            e.response.statusText,
                            resource,
                            e.response.message
                        )
                    );
                }
            }
        } else {
            try {
                const { data } = await authAxios.get(
                    `/api/${resource}/${params.id}`
                );
                return { data };
            } catch (e) {
                if (e.response.status !== 401) {
                    return Swal.fire(
                        errorDetector(
                            e.response.status,
                            e.response.statusText,
                            resource,
                            e.response.message
                        )
                    );
                }
            }
        }
    },
    async getMany(resource, { ids, ...params }) {
        if (resource === 'sectors') {
            try {
                const { data } = await authAxios.get(`/api/${resource}`, {
                    params: {
                        _id: ids.join(','),
                    },
                });
                const newData = data.map((item) => ({ ...item, id: item._id }));

                return { data: newData };
            } catch (e) {
                if (e.response.status !== 401) {
                    return Swal.fire(
                        errorDetector(
                            e.response.status,
                            e.response.statusText,
                            resource,
                            e.response.message
                        )
                    );
                }
            }
        } else if (resource === 'commodity-country') {
            try {
                const { data } = await authAxios.get(`/api/${resource}`);
                const result = data.map((item, index) => ({
                    ...item,
                    id: index,
                }));
                return { data: result };
            } catch (e) {
                if (e.response.status !== 401) {
                    return Swal.fire(
                        errorDetector(
                            e.response.status,
                            e.response.statusText,
                            resource,
                            e.response.message
                        )
                    );
                }
            }
        } else {
            try {
                const { data } = await authAxios.get(`/api/${resource}`, {
                    params: {
                        _id: ids.join(','),
                    },
                });
                return { data };
            } catch (e) {
                if (e.response.status !== 401) {
                    return Swal.fire(
                        errorDetector(
                            e.response.status,
                            e.response.statusText,
                            resource,
                            e.response.message
                        )
                    );
                }
            }
        }
    },
    getManyReference: (resource, params) => {
        console.log('get-many-ref');
    },
    async create(resource, params) {
        const filesToUpload = Object.entries(params.data)
            .filter(([key, value]) => value.rawFile)
            .map(([key, value]) => ({ field: key, file: value.rawFile }));

        const extraData = {};

        if (filesToUpload.length && resource !== 'aboutFile') {
            const uploadedFiles = await this.uploadFiles(
                {
                    files:
                        filesToUpload.length > 1
                            ? filesToUpload.map((entry) => entry.file)
                            : filesToUpload[0].file,
                },
                resource
            );
            if (
                resource === 'sources' ||
                resource === 'publications' ||
                resource === 'commodity-resource' ||
                resource === 'strategies' ||
                resource === 'investmodules' ||
                resource === 'news' ||
                resource === 'document' ||
                resource === 'reviews'
            ) {
                filesToUpload.forEach((fileToUpload, index) => {
                    extraData[fileToUpload.field] = uploadedFiles[index].url;
                });
            } else if (resource !== 'aboutFile') {
                filesToUpload.forEach((fileToUpload, index) => {
                    extraData[fileToUpload.field] = uploadedFiles[index].id;
                });
            }
        }

        const body = {
            ...params.data,
            ...extraData,
        };
        if (resource === 'aboutFile' && filesToUpload.length) {
            const uploadedFiles = await this.uploadFiles(
                {
                    files:
                        filesToUpload.length > 1
                            ? filesToUpload.map((entry) => entry.file)
                            : filesToUpload[0].file,
                },
                resource
            );
            return { data: uploadedFiles };
        } else if (resource === 'rating-industry') {
            const payloadForIndustry = {
                name: body.name,
                country: body.country,
            };
            const payloadForRating = body.rating.map((item) => {
                return { ...item, rating: Number(item.rating) };
            });
            try {
                const { data } = await authAxios.post(
                    `/api/${resource}`,
                    payloadForIndustry
                );
                const idForAddRating = data.id;
                for (let payload of payloadForRating) {
                    // eslint-disable-next-line no-unused-vars
                    const { data: ratingData } = await authAxios.patch(
                        `/api/${resource}/rating/add/${idForAddRating}`,
                        payload
                    );
                }
                return { data };
            } catch (e) {
                if (e.response.status !== 401) {
                    console.log(e.response);
                    return Swal.fire(
                        errorDetector(
                            e.response.status,
                            e.response.statusText,
                            resource,
                            e.response.message
                        )
                    );
                }
            }
        } else if (resource === 'sectors' || resource === 'commodities') {
            try {
                const { data } = await authAxios.post(
                    `/api/${resource}`,
                    params.data
                );
                const newData = { ...data, id: data._id };
                // const newData = data.map(item => ({id: item._id, name: item.name}))
                return { data: newData };
            } catch (e) {
                if (e.response.status !== 401) {
                    return Swal.fire(
                        errorDetector(
                            e.response.status,
                            e.response.statusText,
                            resource,
                            e.response.message
                        )
                    );
                }
            }
        } else if (resource === 'document') {
            if (!body.hasOwnProperty('isVisibleOnMainPage')) {
                body.isVisibleOnMainPage = false;
            }
            try {
                const { data } = await authAxios.post(`/api/document`, body);

                return { data };
            } catch (e) {
                if (e.response.status !== 401) {
                    return Swal.fire(
                        errorDetector(
                            e.response.status,
                            e.response.statusText,
                            resource,
                            e.response.message
                        )
                    );
                }
            }
        } else if (resource === 'news') {
            try {
                const { data } = await authAxios.post(`/api/document`, {
                    ...body,
                    isVisibleOnMainPage: false,
                    type: 'news',
                });

                return { data };
            } catch (e) {
                if (e.response.status !== 401) {
                    return Swal.fire(
                        errorDetector(
                            e.response.status,
                            e.response.statusText,
                            resource,
                            e.response.message
                        )
                    );
                }
            }
        } else if (resource === 'sources') {
            if (!body.hasOwnProperty('isVisibleOnMainPage')) {
                body.isVisibleOnMainPage = false;
            }
            const position = await checkSourcePosition(body.category);
            try {
                const { data } = await authAxios.post(`/api/${resource}`, {
                    ...body,
                    position,
                });

                return { data };
            } catch (e) {
                if (e.response.status !== 401) {
                    return Swal.fire(
                        errorDetector(
                            e.response.status,
                            e.response.statusText,
                            resource,
                            e.response.message
                        )
                    );
                }
            }
        } else if (resource === 'commodity-resource') {
            const { data: country } = await this.getList(
                'commodity-country',
                {}
            );
            const payload = {
                name: body.name,
                icon: body.icon,
                resourceCategoryId: body.resourceCategoryId,
                data: [],
            };
            if (body.data.length) {
                body.data.forEach((item) => {
                    payload.data.push({
                        country: {
                            name: country.find(
                                (countryItem) =>
                                    countryItem.id === item.country_name
                            ).name,
                            icon: country.find(
                                (countryItem) =>
                                    countryItem.id === item.country_name
                            ).icon,
                        },
                        companies: item.companies.map((item) => {
                            return item.company;
                        }),
                    });
                });
            }
            try {
                const { data } = await authAxios.post(
                    `/api/${resource}`,
                    payload
                );

                return { data };
            } catch (e) {
                if (e.response.status !== 401) {
                    return Swal.fire(
                        errorDetector(
                            e.response.status,
                            e.response.statusText,
                            resource,
                            e.response.message
                        )
                    );
                }
            }
        } else if (resource === 'investideas') {
            const payload = {
                ...body,
                startDate: new Date(body.startDate)
                    .toISOString()
                    .replace('.000Z', '.910+00:00'),
                endDate: new Date(body.endDate)
                    .toISOString()
                    .replace('.000Z', '.910+00:00'),
            };
            try {
                const { data } = await authAxios.post(
                    `/api/${resource}`,
                    payload
                );

                return { data };
            } catch (e) {
                if (e.response.status !== 401) {
                    return Swal.fire(
                        errorDetector(
                            e.response.status,
                            e.response.statusText,
                            resource,
                            e.response.message
                        )
                    );
                }
            }
        } else if (resource === 'strategies' || resource === 'investmodules') {
            try {
                const { data, id } = await authAxios.post(
                    `/api/${resource}`,
                    body
                );

                return {
                    data: {
                        id,
                        ...data,
                    },
                };
            } catch (e) {
                if (e.response.status !== 401) {
                    return Swal.fire(
                        errorDetector(
                            e.response.status,
                            e.response.statusText,
                            resource,
                            e.response.message
                        )
                    );
                }
            }
        } else {
            try {
                const { data } = await authAxios.post(`/api/${resource}`, body);

                return { data };
            } catch (e) {
                if (e.response.status !== 401) {
                    return Swal.fire(
                        errorDetector(
                            e.response.status,
                            e.response.statusText,
                            resource,
                            e.response.message
                        )
                    );
                }
            }
        }
    },
    async update(resource, params) {
        const filesToUpload = Object.entries(params.data)
            // eslint-disable-next-line array-callback-return
            .filter(([key, value]) => {
                if (value) {
                    return value.rawFile;
                }
            })
            .map(([key, value]) => ({ field: key, file: value.rawFile }));

        const extraData = {};

        if (filesToUpload.length) {
            const uploadedFiles = await this.uploadFiles({
                files:
                    filesToUpload.length > 1
                        ? filesToUpload.map((entry) => entry.file)
                        : filesToUpload[0].file,
            });
            if (
                resource === 'sources' ||
                resource === 'publications' ||
                resource === 'commodity-resource' ||
                resource === 'strategies' ||
                resource === 'investmodules' ||
                resource === 'news' ||
                resource === 'document' ||
                resource === 'reviews'
            ) {
                filesToUpload.forEach((fileToUpload, index) => {
                    extraData[fileToUpload.field] = uploadedFiles[index].url;
                });
            } else if (resource !== 'aboutFile') {
                filesToUpload.forEach((fileToUpload, index) => {
                    extraData[fileToUpload.field] = uploadedFiles[index].id;
                });
            }
        }

        // для уже загруженных файлов, чтобы вместо полного объекта отправлять только id
        const uploadedFileIds = Object.entries(params.data)
            // eslint-disable-next-line array-callback-return
            .filter(([key, value]) => {
                if (value) {
                    return value.fileName;
                }
            })
            .map(([key, value]) => ({ [key]: value.id }));

        // для связанных сущностей, которые имеют свой id
        const relatedEntities = Object.entries(params.data)
            // eslint-disable-next-line array-callback-return
            .filter(([key, value]) => {
                if (value) {
                    return value.id;
                }
            })
            .map(([key, value]) => ({ [key]: value.id }));

        const payloadData = {
            ...params.data,
            rating: +params.data.rating,
            ...Object.assign({}, ...uploadedFileIds),
            ...Object.assign({}, ...relatedEntities),
            ...extraData,
        };
        if (
            resource === 'companies' ||
            resource === 'industries' ||
            resource === 'reviews'
        ) {
            try {
                const { data } = await authAxios.put(
                    `/api/${resource}/${params.id}`,
                    payloadData
                );
                return { data };
            } catch (e) {
                if (e.response.status !== 401) {
                    return Swal.fire(
                        errorDetector(
                            e.response.status,
                            e.response.statusText,
                            resource,
                            e.response.message
                        )
                    );
                }
            }
        } else if (resource === 'sectors') {
            try {
                const { data } = await authAxios.put(
                    `/api/${resource}/${params.id}`,
                    { name: params.data.name }
                );
                const newData = { ...data, id: data._id };
                return { data: newData };
            } catch (e) {
                if (e.response.status !== 401) {
                    return Swal.fire(
                        errorDetector(
                            e.response.status,
                            e.response.statusText,
                            resource,
                            e.response.message
                        )
                    );
                }
            }
        } else if (resource === 'commodities') {
            const payload = {
                name: params.data.name,
                ticker: params.data.ticker,
                symbol: params.data.symbol,
                sector_id: params.data.sector_id,
                growth_potential: params.data.growth_potential,
            };
            try {
                const { data } = await authAxios.patch(
                    `/api/${resource}/${params.id}`,
                    payload
                );
                const newData = { ...data, id: data._id };
                return { data: newData };
            } catch (e) {
                if (e.response.status !== 401) {
                    return Swal.fire(
                        errorDetector(
                            e.response.status,
                            e.response.statusText,
                            resource,
                            e.response.message
                        )
                    );
                }
            }
        } else if (resource === 'about') {
            try {
                const { data } = await authAxios.put(
                    `/api/${resource}`,
                    params.data
                );
                return { data };
            } catch (e) {
                if (e.response.status !== 401) {
                    return Swal.fire(
                        errorDetector(
                            e.response.status,
                            e.response.statusText,
                            resource,
                            e.response.message
                        )
                    );
                }
            }
        } else if (resource === 'investideas') {
            const payload = {
                ...params?.data,
                startDate: new Date(params?.data?.startDate)
                    .toISOString()
                    .replace('.000Z', '.910+00:00'),
                endDate: new Date(params?.data?.endDate)
                    .toISOString()
                    .replace('.000Z', '.910+00:00'),
            };
            try {
                const { data } = await authAxios.patch(
                    `/api/${resource}/${params.id}`,
                    payload
                );
                return { data };
            } catch (e) {
                if (e.response.status !== 401) {
                    return Swal.fire(
                        errorDetector(
                            e.response.status,
                            e.response.statusText,
                            resource,
                            e.response.message
                        )
                    );
                }
            }
        } else if (resource === 'aboutFile') {
            try {
                const { data } = await authAxios.put(
                    `/api/about/file/${params.id}`,
                    payloadData
                );
                return { data };
            } catch (e) {
                if (e.response.status !== 401) {
                    return Swal.fire(
                        errorDetector(
                            e.response.status,
                            e.response.statusText,
                            resource,
                            e.response.message
                        )
                    );
                }
            }
        } else if (resource === 'source') {
            try {
                const { data } = await authAxios.put(
                    `/api/sources/${params.id}`,
                    payloadData
                );
                return { data };
            } catch (e) {
                if (e.response.status !== 401) {
                    return Swal.fire(
                        errorDetector(
                            e.response.status,
                            e.response.statusText,
                            resource,
                            e.response.message
                        )
                    );
                }
            }
        } else if (resource === 'rating-industry') {
            const basicPayload = { name: payloadData.name };
            let arrayForRating = [];
            if (params.data.rating.length) {
                arrayForRating = arrayForRating.concat(params.data.rating);
            }
            if (params.data.newRating?.length) {
                arrayForRating = arrayForRating.concat(params.data.newRating);
            }
            const ratingPayload = arrayForRating.map((item) => {
                return { ...item, rating: Number(item.rating) };
            });
            const ratingForDelete = checkingDeletedItem(
                params.previousData.rating,
                params.data.rating
            );
            try {
                const { data: basicData } = await authAxios.patch(
                    `/api/${resource}/${params.id}`,
                    basicPayload
                );
                for (let payload of ratingPayload) {
                    // eslint-disable-next-line no-unused-vars
                    const { data: ratingData } = await authAxios.patch(
                        `/api/${resource}/rating/add/${params.id}`,
                        payload
                    );
                }
                if (ratingForDelete.length) {
                    for (let payload of ratingForDelete) {
                        // eslint-disable-next-line no-unused-vars
                        const { data: deleteRating } = await authAxios.patch(
                            `/api/rating-industry/rating/remove/${params.id}`,
                            payload
                        );
                    }
                }
                return { data: basicData };
            } catch (e) {
                if (e.response.status !== 401) {
                    return Swal.fire(
                        errorDetector(
                            e.response.status,
                            e.response.statusText,
                            resource,
                            e.response.message
                        )
                    );
                }
            }
        } else if (resource === 'publications') {
            try {
                const { data } = await authAxios.put(
                    `/api/publications/${params.id}`,
                    payloadData
                );
                return { data };
            } catch (e) {
                if (e.response.status !== 401) {
                    return Swal.fire(
                        errorDetector(
                            e.response.status,
                            e.response.statusText,
                            resource,
                            e.response.message
                        )
                    );
                }
            }
        } else if (resource === 'news') {
            try {
                const { data } = await authAxios.patch(
                    `/api/document/${params.id}`,
                    {
                        ...payloadData,
                        isVisibleOnMainPage: false,
                        type: 'news',
                    }
                );
                return { data };
            } catch (e) {
                if (e.response.status !== 401) {
                    return Swal.fire(
                        errorDetector(
                            e.response.status,
                            e.response.statusText,
                            resource,
                            e.response.message
                        )
                    );
                }
            }
        } else if (resource === 'commodity-resource') {
            const { data: country } = await this.getList(
                'commodity-country',
                {}
            );
            const payload = {
                name: payloadData.name,
                icon: payloadData.icon,
                resourceCategoryId: payloadData.resourceCategoryId,
                data: [],
            };
            if (payloadData.data.length) {
                payloadData.data.forEach((item) => {
                    payload.data.push({
                        country: {
                            name: country.find(
                                (countryItem) =>
                                    countryItem.id === item.country_name
                            ).name,
                            icon: country.find(
                                (countryItem) =>
                                    countryItem.id === item.country_name
                            ).icon,
                        },
                        companies: item.companies.map((item) => {
                            return item.company;
                        }),
                    });
                });
            }
            try {
                const { data } = await authAxios.patch(
                    `/api/${resource}/${params.id}`,
                    payload
                );

                return { data };
            } catch (e) {
                if (e.response.status !== 401) {
                    return Swal.fire(
                        errorDetector(
                            e.response.status,
                            e.response.statusText,
                            resource,
                            e.response.message
                        )
                    );
                }
            }
        } else {
            try {
                const { data } = await authAxios.patch(
                    `/api/${resource}/${params.id}`,
                    payloadData
                );
                return { data };
            } catch (e) {
                if (e.response.status !== 401) {
                    return Swal.fire(
                        errorDetector(
                            e.response.status,
                            e.response.statusText,
                            resource,
                            e.response.message
                        )
                    );
                }
            }
        }
    },
    updateMany: (resource, params) => Promise,
    async delete(resource, params) {
        if (resource === 'aboutFile') {
            try {
                const { data } = await authAxios.delete(
                    `/api/about/file/${params.id}`
                );
                return { data };
            } catch (e) {
                if (e.response.status !== 401) {
                    return Swal.fire(
                        errorDetector(
                            e.response.status,
                            e.response.statusText,
                            params.id,
                            e.response.message
                        )
                    );
                }
            }
        } else if (resource === 'news') {
            try {
                const { data } = await authAxios.delete(
                    `/api/document/${params.id}`
                );
                return { data };
            } catch (e) {
                if (e.response.status !== 401) {
                    return Swal.fire(
                        errorDetector(
                            e.response.status,
                            e.response.statusText,
                            params.id,
                            e.response.message
                        )
                    );
                }
            }
        } else {
            try {
                const { data } = await authAxios.delete(
                    `/api/${resource}/${params.id}`
                );
                return { data };
            } catch (e) {
                if (e.response.status !== 401) {
                    return Swal.fire(
                        errorDetector(
                            e.response.status,
                            e.response.statusText,
                            params.id,
                            e.response.message
                        )
                    );
                }
            }
        }
    },
    async deleteMany(resource, params) {
        try {
            for (const idToDelete of params.ids) {
                await this.delete(resource, { id: idToDelete });
            }
            // return {data: {ok: true}}
            return { data: [{ ok: true }] };
        } catch (e) {
            if (e.response.status !== 401) {
                return Swal.fire(
                    errorDetector(
                        e.response.status,
                        e.response.statusText,
                        resource,
                        e.response.message
                    )
                );
            }
        }
    },
    async uploadFiles({ files }, resource) {
        const filesToUpload =
            files.length && files.length > 1
                ? [...files]
                : [files].filter(Boolean);
        const body = new FormData();
        if (filesToUpload.length) {
            if (resource === 'aboutFile') {
                filesToUpload.forEach((file) =>
                    body.append('file', file, file.name)
                );
                try {
                    const { data } = await authAxios.post(
                        `/api/about/file/`,
                        body,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        }
                    );
                    return data;
                } catch (e) {
                    console.error(e);
                    if (e.response.status !== 401) {
                        return Swal.fire(
                            errorDetector(
                                e.response.status,
                                e.response.statusText,
                                e.response.message
                            )
                        );
                    }
                }
            } else {
                filesToUpload.forEach((file) =>
                    body.append('files', file, file.name)
                );
                try {
                    const { data } = await authAxios.post(
                        `/api/file/upload`,
                        body,
                        {
                            headers: {
                                'Content-Type': 'multipart/form-data',
                            },
                        }
                    );
                    return data;
                } catch (e) {
                    console.error(e);
                    if (e.response.status !== 401) {
                        return Swal.fire(
                            errorDetector(
                                e.response.status,
                                e.response.statusText,
                                e.response.message
                            )
                        );
                    }
                }
            }
        }
        throw new Error('Файлы не выбраны.');
    },
};

export default dataProvider;
