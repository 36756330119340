import {
    Datagrid,
    DateField,
    List,
    SelectField,
    TextField,
} from 'ra-ui-materialui';

export const NewsList = (props) => {
    return (
        <List {...props} exporter={false} title="Новости">
            <Datagrid rowClick="edit">
                <TextField label="Название" source="title" />
                <TextField label="URL" source="url" />
                <SelectField
                    choices={[
                        { id: 'draft', name: 'Черновик' },
                        { id: 'published', name: 'Опубликован' },
                    ]}
                    source="status"
                    label="Статус публикации"
                />
                <SelectField
                    label="Тип"
                    source="type"
                    choices={[
                        { id: 'document', name: 'Документ' },
                        { id: 'article', name: 'Статья' },
                        { id: 'news', name: 'Новости' },
                        { id: 'video', name: 'Видео' },
                    ]}
                />
                <DateField source="createdDate" label="Дата создания" />
            </Datagrid>
        </List>
    );
};
