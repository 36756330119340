import { Edit, SelectInput, SimpleForm, TextInput } from 'ra-ui-materialui';
import { NumberInput } from 'react-admin';
import useDebouncedFunction from '../../helpers/useDebounce';
import axios from '../../helpers/axios';
import { Field, FormSpy } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { required } from 'ra-core';
import React, { useState } from 'react';
import styled from 'styled-components';

const Wrap = styled.div`
    display: flex;
`;

const ErrorMessage = styled.p`
    margin-left: 40px;
    font-size: 16px;
    line-height: 18px;
`;

export const StrategyToolsEdit = (props) => {
    const [fetching, setFetching] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);

    const ObserveTickerFieldForAmount = ({
        tickerFieldName = 'ticker',
        amountFieldName = 'price',
        currencyName = 'currency',
    }) => {
        const debounced = useDebouncedFunction(
            (newValue, callback) => handleChange(newValue, callback),
            1000
        );

        const handleChange = async (value, callback) => {
            setFetching(true);

            const currency = document.getElementsByName(currencyName)[0].value;
            const { data } = await axios.get(
                `/api/strategy-tools/last-tick?symbol=${encodeURIComponent(
                    value
                )}&currency=${currency}`
            );

            if (data) {
                callback(data.price);
            } else {
                setErrorMessage(true);
            }

            setFetching(false);
        };

        return (
            <Field name={amountFieldName} subscription={{}}>
                {(
                    // No subscription. We only use Field to get to the change function
                    { input: { onChange } }
                ) => (
                    <FormSpy subscription={{}}>
                        {() => (
                            <OnChange name={tickerFieldName}>
                                {(value) => {
                                    debounced(value, onChange);
                                }}
                            </OnChange>
                        )}
                    </FormSpy>
                )}
            </Field>
        );
    };

    const CustomField = (props) => {
        return (
            <Wrap>
                <TextInput
                    validate={required()}
                    source={props.source}
                    label="Тикер"
                />

                {fetching ? (
                    <ErrorMessage>Загрузка...</ErrorMessage>
                ) : (
                    errorMessage && <ErrorMessage>Тикер не найден</ErrorMessage>
                )}
            </Wrap>
        );
    };

    return (
        <Edit title="Создание стратегии" {...props}>
            <SimpleForm>
                <TextInput label="Название" source="title" />
                <TextInput label="Индустрия" source="industry" />
                <TextInput label="Описание" source="description" fullWidth />
                <SelectInput
                    validate={required()}
                    label="Валюта"
                    source="currency"
                    choices={[
                        { id: 'USD', name: 'USD' },
                        { id: 'RUB', name: 'RUB' },
                    ]}
                />
                <CustomField source="ticker" />
                <ObserveTickerFieldForAmount />
                <NumberInput source="price" label="Цена" />
            </SimpleForm>
        </Edit>
    );
};
