import { Datagrid, List, TextField } from 'ra-ui-materialui';
import * as React from 'react';

export const InvestmodulesToolsList = (props) => {
    return (
        <List {...props} exporter={false} title="Секторы">
            <Datagrid rowClick="edit">
                <TextField label="Название" source="title" />
                <TextField label="Тикер" source="ticker" />
                <TextField label="Описание" source="description" />
                <TextField label="Цена (RUB)" source="priceRub" />
                <TextField label="Цена (USD)" source="priceUsd" />
            </Datagrid>
        </List>
    );
};
