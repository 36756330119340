import { Datagrid, List, TextField } from 'ra-ui-materialui';
import { ImageField } from 'react-admin';

export const ResourceCommodityList = (props) => {
    return (
        <List {...props} exporter={false} title="Новости">
            <Datagrid rowClick="edit">
                <TextField label="Название" source="name" />
                <ImageField label="Иконка" source="icon" />
                <TextField label="Категория" source="category" />
            </Datagrid>
        </List>
    );
};
