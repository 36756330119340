import {
    Edit,
    DateInput,
    ImageInput,
    SelectInput,
    SimpleForm,
    TextInput,
} from 'ra-ui-materialui';
import { ImageField } from 'react-admin';

export const PublicationEdit = (props) => {
    return (
        <Edit title="Создание отрасли" {...props}>
            <SimpleForm>
                <TextInput label="Превью" source="preview" />
                <ImageInput source="logo" label="Логотип">
                    <ImageField />
                </ImageInput>
                <TextInput source="link" label="Ссылка" />
                <DateInput source="publicationDate" label="Дата" />
                <SelectInput
                    choices={[
                        { id: 'draft', name: 'Черновик' },
                        { id: 'published', name: 'Опубликована' },
                    ]}
                    source="status"
                    label="Статус новости"
                />
            </SimpleForm>
        </Edit>
    );
};
