import { Edit, SelectInput, SimpleForm, TextInput } from 'ra-ui-materialui';
import { NumberInput } from 'react-admin';
import useDebouncedFunction from '../../helpers/useDebounce';
import axios from '../../helpers/axios';
import { Field, FormSpy } from 'react-final-form';
import { OnChange } from 'react-final-form-listeners';
import { required } from 'ra-core';
import React, { useState } from 'react';
import styled from 'styled-components';

const Wrap = styled.div`
    display: flex;
`;

const ErrorMessage = styled.p`
    margin-left: 40px;
    font-size: 16px;
    line-height: 18px;
`;

export const InvestmoduleToolsEdit = (props) => {
    const [fetching, setFetching] = useState(false);
    const [errorMessage, setErrorMessage] = useState(false);

    const ObserveTickerFieldForPriceInitial = ({
        onChangeFieldName = 'ticker',
        fieldName = 'priceInitial',
        currencyName = 'currency',
    }) => {
        const debounced = useDebouncedFunction(
            (newValue, callback) => handleChange(newValue, callback),
            1000
        );

        const handleChange = async (value, callback) => {
            setFetching(true);

            const currency = document.getElementsByName(currencyName)[0].value;

            const url = `/api/investmodule-tools/last-tick?symbol=${encodeURIComponent(
                value
            )}&currency=${currency}`;

            const { data } = await axios.get(url);

            if (data) {
                callback(data.priceInitial);
            } else {
                setErrorMessage(true);
            }

            setFetching(false);
        };

        return (
            <Field name={fieldName} subscription={{}}>
                {({ input: { onChange } }) => (
                    <FormSpy subscription={{}}>
                        {() => (
                            <OnChange name={onChangeFieldName}>
                                {(value) => {
                                    debounced(value, onChange);
                                }}
                            </OnChange>
                        )}
                    </FormSpy>
                )}
            </Field>
        );
    };

    const ObserveTickerFieldForExchangeRate = ({
        onChangeFieldName = 'ticker',
        fieldName = 'exchangeRate',
        currencyName = 'currency',
    }) => {
        const debounced = useDebouncedFunction(
            (newValue, callback) => handleChange(newValue, callback),
            1000
        );

        const handleChange = async (value, callback) => {
            setFetching(true);

            const currency = document.getElementsByName(currencyName)[0].value;

            const url = `/api/investmodule-tools/last-tick?symbol=${encodeURIComponent(
                value
            )}&currency=${currency}`;

            const { data } = await axios.get(url);

            if (data) {
                callback(data.exchangeRate);
            } else {
                setErrorMessage(true);
            }

            setFetching(false);
        };

        return (
            <Field name={fieldName} subscription={{}}>
                {({ input: { onChange } }) => (
                    <FormSpy subscription={{}}>
                        {() => (
                            <OnChange name={onChangeFieldName}>
                                {(value) => {
                                    debounced(value, onChange);
                                }}
                            </OnChange>
                        )}
                    </FormSpy>
                )}
            </Field>
        );
    };

    const ObserveTickerFieldForPriceRub = ({
        onChangeFieldName = 'ticker',
        fieldName = 'priceRub',
        currencyName = 'currency',
    }) => {
        const debounced = useDebouncedFunction(
            (newValue, callback) => handleChange(newValue, callback),
            1000
        );

        const handleChange = async (value, callback) => {
            setFetching(true);

            const currency = document.getElementsByName(currencyName)[0].value;

            const url = `/api/investmodule-tools/last-tick?symbol=${encodeURIComponent(
                value
            )}&currency=${currency}`;

            const { data } = await axios.get(url);

            if (data) {
                callback(data.priceRub);
            } else {
                setErrorMessage(true);
            }

            setFetching(false);
        };

        return (
            <Field name={fieldName} subscription={{}}>
                {({ input: { onChange } }) => (
                    <FormSpy subscription={{}}>
                        {() => (
                            <OnChange name={onChangeFieldName}>
                                {(value) => {
                                    debounced(value, onChange);
                                }}
                            </OnChange>
                        )}
                    </FormSpy>
                )}
            </Field>
        );
    };

    const ObserveTickerFieldForPriceUsd = ({
        onChangeFieldName = 'ticker',
        fieldName = 'priceUsd',
        currencyName = 'currency',
    }) => {
        const debounced = useDebouncedFunction(
            (newValue, callback) => handleChange(newValue, callback),
            1000
        );

        const handleChange = async (value, callback) => {
            setFetching(true);

            const currency = document.getElementsByName(currencyName)[0].value;

            const url = `/api/investmodule-tools/last-tick?symbol=${encodeURIComponent(
                value
            )}&currency=${currency}`;

            const { data } = await axios.get(url);

            if (data) {
                callback(data.priceUsd);
            } else {
                setErrorMessage(true);
            }

            setFetching(false);
        };

        return (
            <Field name={fieldName} subscription={{}}>
                {({ input: { onChange } }) => (
                    <FormSpy subscription={{}}>
                        {() => (
                            <OnChange name={onChangeFieldName}>
                                {(value) => {
                                    debounced(value, onChange);
                                }}
                            </OnChange>
                        )}
                    </FormSpy>
                )}
            </Field>
        );
    };

    const CustomField = (props) => {
        return (
            <Wrap>
                <TextInput
                    validate={required()}
                    source={props.source}
                    label="Тикер"
                />
                {fetching ? (
                    <ErrorMessage>Загрузка...</ErrorMessage>
                ) : (
                    errorMessage && <ErrorMessage>Тикер не найден</ErrorMessage>
                )}
            </Wrap>
        );
    };

    return (
        <Edit title="Создание инвестиционного модуля" {...props}>
            <SimpleForm>
                <TextInput label="Название" source="title" />
                <TextInput label="Описание" source="description" fullWidth />
                <SelectInput
                    validate={required()}
                    label="Валюта тикера"
                    source="currency"
                    choices={[
                        { id: 'USD', name: 'USD' },
                        { id: 'RUB', name: 'RUB' },
                    ]}
                />
                <CustomField source="ticker" />

                <ObserveTickerFieldForPriceInitial />
                <ObserveTickerFieldForExchangeRate />

                <ObserveTickerFieldForPriceRub />
                <NumberInput
                    validate={required()}
                    source="priceRub"
                    label="Цена (RUB)"
                />

                <ObserveTickerFieldForPriceUsd />
                <NumberInput
                    validate={required()}
                    source="priceUsd"
                    label="Цена (USD)"
                />
            </SimpleForm>
        </Edit>
    );
};
