import { Datagrid, List, TextField } from 'ra-ui-materialui';
import * as React from 'react';

export const StrategiesToolsList = (props) => {
    return (
        <List {...props} exporter={false} title="Секторы">
            <Datagrid rowClick="edit">
                <TextField label="Название" source="title" />
                <TextField label="Индустрия" source="industry" />
                <TextField label="Тикер" source="ticker" />
                <TextField label="Описание" source="description" />
                <TextField source="price" label="Цена" />
            </Datagrid>
        </List>
    );
};
