import { Edit, SelectInput, SimpleForm, TextInput } from 'ra-ui-materialui';

const PageTitle = ({ record }) => {
    return <span>Отрасль {record ? `"${record.name}"` : ''}</span>;
};

export const IndustryEdit = (props) => {
    return (
        <Edit title={<PageTitle />} mutationMode="optimistic" {...props}>
            <SimpleForm>
                <TextInput label="Название" source="name" />
                <SelectInput
                    choices={[
                        { id: 'draft', name: 'Черновик' },
                        { id: 'published', name: 'Опубликована' },
                    ]}
                    source="publicationStatus"
                    label="Статус публикации"
                />
            </SimpleForm>
        </Edit>
    );
};
