import { required } from 'ra-core';
import { SimpleForm, TextInput, Create } from 'ra-ui-materialui';

export const PartnersCategoriesCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <TextInput
                validate={required()}
                source="title"
                label={`Название`}
            />
            <TextInput
                validate={required()}
                source="description"
                label={`Описание`}
            />
        </SimpleForm>
    </Create>
);
