import { Datagrid, List, TextField } from 'ra-ui-materialui';
import * as React from 'react';

export const SectorList = (props) => {
    return (
        <List {...props} exporter={false} title="Секторы" pagination={false}>
            <Datagrid rowClick="edit">
                <TextField label="ID" source="id" />
                <TextField label="Название" source="name" />
            </Datagrid>
        </List>
    );
};
