import { required } from 'ra-core';
import {
    SelectInput,
    SimpleForm,
    TextInput,
    Edit,
    ImageInput,
    NumberInput,
} from 'ra-ui-materialui';
import { ImageField, ReferenceInput } from 'react-admin';

export const PartnersEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput
                validate={required()}
                source="title"
                label={`Название`}
            />
            <TextInput validate={required()} source="link" label={`Ссылка`} />
            <ImageInput source="logo" label="Новое лого" multiple={false}>
                <ImageField />
            </ImageInput>
            <ImageField source="logo" label="Нынешнее лого" />
            <SelectInput
                source={`status`}
                label={`Статус`}
                choices={[
                    { id: 'draft', name: 'Черновик' },
                    { id: 'published', name: 'Опубликован' },
                ]}
            />
            <TextInput
                validate={required()}
                source={`description`}
                label={`Описание`}
            />
            <ReferenceInput
                reference="source-categories"
                source="category"
                label="Категория"
                validate={required()}
            >
                <SelectInput optionText="title" />
            </ReferenceInput>
            <NumberInput source="position" label="Позиция" />
        </SimpleForm>
    </Edit>
);
