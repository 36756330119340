import {
    ArrayInput,
    ImageInput,
    SelectInput,
    SimpleForm,
    Edit,
    TextInput,
} from 'ra-ui-materialui';
import * as React from 'react';
import {
    AutocompleteInput,
    ImageField,
    ReferenceInput,
    SimpleFormIterator,
} from 'react-admin';

export const ResourceCommodityEdit = (props) => {
    return (
        <Edit {...props}>
            <SimpleForm>
                <TextInput label="Название" fullWidth source="name" />
                <ImageInput
                    source="icon"
                    labelSingle="иконка отрасли"
                    multiple={false}
                >
                    <ImageField />
                </ImageInput>
                <ReferenceInput
                    label="Категория"
                    reference="resource-category"
                    source="resourceCategoryId"
                >
                    <SelectInput
                        label="Категория"
                        source="resourceCategoryId"
                    />
                </ReferenceInput>
                <ArrayInput source="data" label="Данные">
                    <SimpleFormIterator>
                        <ReferenceInput
                            reference="commodity-country"
                            label="Страна"
                            source="country_name"
                        >
                            <AutocompleteInput label="Страна" source="name" />
                        </ReferenceInput>
                        <ArrayInput source="companies" label="Компании">
                            <SimpleFormIterator>
                                <TextInput source="company" label="Компания" />
                            </SimpleFormIterator>
                        </ArrayInput>
                    </SimpleFormIterator>
                </ArrayInput>
            </SimpleForm>
        </Edit>
    );
};
