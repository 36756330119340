import { Admin, Layout, Resource } from 'react-admin';
import TreeMenu from '@bb-tech/ra-treemenu';
import DocumentIcon from '@material-ui/icons/Description';
import RatingIcon from '@material-ui/icons/TrendingUp';
import ListIcon from '@material-ui/icons/List';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import CategoryIcon from '@material-ui/icons/Category';
import StarHalfIcon from '@material-ui/icons/StarHalf';
import polyglotI18nProvider from 'ra-i18n-polyglot';
import russianMessages from 'ra-language-russian';

import authProvider from './data-providers/auth-provider';
import dataProvider from './data-providers/data-provider';
import { DocumentList } from './entities/document/list';
import { DocumentEdit } from './entities/document/edit-view';
import { DocumentCreate } from './entities/document/create-view';
import { ProductList } from './entities/product/list';
import { ProductEdit } from './entities/product/edit-view';
import { ProductCreate } from './entities/product/create-view';
import { ProductCategoryEdit } from './entities/product-category/edit-view';
import { ProductCategoryCreate } from './entities/product-category/create-view';
import { ProductCategoryList } from './entities/product-category/list';
import { CompaniesList } from './entities/companies/list';
import { CompanyEdit } from './entities/companies/edit-view';
import { CompanyCreate } from './entities/companies/create-view';
import { IndustriesList } from './entities/industries/list';
import { IndustryCreate } from './entities/industries/create-view';
import { IndustryEdit } from './entities/industries/edit-view';
import { Contacts } from '@material-ui/icons';
import { RequisitesList } from './entities/contacts/requesites/list';
import { RequisitesEdit } from './entities/contacts/requesites/edit-view';
import { DocumentsContactsList } from './entities/contacts/documents/list';
import { DocumentsContactsCreate } from './entities/contacts/documents/create-view';
import { DocumentsContactsEdit } from './entities/contacts/documents/edit-view';
import { RatingIndustryList } from './entities/rating-industry/list';
import { RatingIndustryCreate } from './entities/rating-industry/create-view';
import { RatingIndustryEdit } from './entities/rating-industry/edit-view';
import { SectorList } from './entities/sector/list';
import { SectorCreate } from './entities/sector/create-view';
import { SectorEdit } from './entities/sector/edit-view';
import { CommodityList } from './entities/commodity/list';
import { CommodityCreate } from './entities/commodity/create-view';
import { CommodityEdit } from './entities/commodity/edit-view';
import { PartnersList } from './entities/partners/sources/list';
import { PartnersCreate } from './entities/partners/sources/create-view';
import { PartnersCategoriesList } from './entities/partners/source-categories/list';
import { PartnersEdit } from './entities/partners/sources/edit-view';
import { PartnersCategoriesCreate } from './entities/partners/source-categories/create-view';
import { PartnersCategoriesEdit } from './entities/partners/source-categories/edit-view';
import { PublicationsList } from './entities/publications/list';
import { PublicationsCreate } from './entities/publications/create-view';
import { PublicationEdit } from './entities/publications/edit-view';
import { NewsList } from './entities/news/list';
import { NewsCreate } from './entities/news/create-view';
import { NewsEdit } from './entities/news/edit-view';
import { ResourceCategoryList } from './entities/resources/resource-category/list';
import { ResourceCategoryCreate } from './entities/resources/resource-category/create-view';
import { ResourceCategoryEdit } from './entities/resources/resource-category/edit-view';
import { ResourceCommodityList } from './entities/resources/commodity-resource/list';
import { ResourceCommodityCreate } from './entities/resources/commodity-resource/create-view';
import { ResourceCommodityEdit } from './entities/resources/commodity-resource/edit-view';
import { CommodityCountryList } from './entities/commodity-country/list';
import { StrategiesList } from './entities/strategies/list';
import { StrategyCreate } from './entities/strategies/create-view';
import { StrategyEdit } from './entities/strategies/edit-view';
import { StrategiesToolsList } from './entities/strategy-tools/list';
import { StrategyToolsCreate } from './entities/strategy-tools/create-view';
import { StrategyToolsEdit } from './entities/strategy-tools/edit-view';
import { InvestmodulesList } from './entities/investmodules/list';
import { InvestmoduleCreate } from './entities/investmodules/create-view';
import { InvestmoduleEdit } from './entities/investmodules/edit-view';
import { InvestmodulesToolsList } from './entities/investmodule-tools/list';
import { InvestmoduleToolsCreate } from './entities/investmodule-tools/create-view';
import { InvestmoduleToolsEdit } from './entities/investmodule-tools/edit-view';
import { InvestIdeasList } from './entities/investiedas/list';
import { InvestIdeasCreate } from './entities/investiedas/create-view';
import { InvestIdeasEdit } from './entities/investiedas/edit-view';
import { ReviewsList } from './entities/reviews/list';
import { ReviewCreate } from './entities/reviews/create-view';
import { ReviewEdit } from './entities/reviews/edit-view';

const i18nProvider = polyglotI18nProvider(() => russianMessages, 'ru');

function App() {
    return (
        <Admin
            layout={(props) => <Layout {...props} menu={TreeMenu} />}
            i18nProvider={i18nProvider}
            title="TR CMS"
            dataProvider={dataProvider}
            authProvider={authProvider}
        >
            <Resource
                options={{ label: 'Документы' }}
                icon={DocumentIcon}
                name="document"
                list={DocumentList}
                edit={DocumentEdit}
                create={DocumentCreate}
            />

            <Resource
                options={{ label: 'Рейтинги', isMenuParent: true }}
                icon={RatingIcon}
                name="ratings"
            />
            <Resource
                edit={IndustryEdit}
                create={IndustryCreate}
                icon={CategoryIcon}
                options={{ label: 'Отрасли', menuParent: 'ratings' }}
                list={IndustriesList}
                name="industries"
            />
            <Resource
                edit={CompanyEdit}
                icon={ListIcon}
                create={CompanyCreate}
                options={{ label: 'Компании', menuParent: 'ratings' }}
                list={CompaniesList}
                name="companies"
            />
            <Resource
                name={'rating-industry'}
                options={{ label: 'Рейтинг индустрий', menuParent: 'ratings' }}
                list={RatingIndustryList}
                create={RatingIndustryCreate}
                edit={RatingIndustryEdit}
            />

            <Resource
                icon={ShoppingCartIcon}
                options={{ label: 'Продукты', isMenuParent: true }}
                name="products-parent"
            />

            <Resource
                edit={ProductEdit}
                create={ProductCreate}
                icon={ShoppingCartIcon}
                options={{ label: 'Продукты', menuParent: 'products-parent' }}
                list={ProductList}
                name="product"
            />
            <Resource
                edit={ProductCategoryEdit}
                icon={ShoppingCartIcon}
                create={ProductCategoryCreate}
                options={{ label: 'Категории', menuParent: 'products-parent' }}
                list={ProductCategoryList}
                name="product-category"
            />
            <Resource
                options={{ label: 'Контакты', isMenuParent: true }}
                icon={Contacts}
                name="contacts"
            />
            <Resource
                icon={Contacts}
                options={{ label: 'Реквизиты', menuParent: 'contacts' }}
                edit={RequisitesEdit}
                list={RequisitesList}
                name="about"
            />
            <Resource
                icon={Contacts}
                options={{ label: 'Документы', menuParent: 'contacts' }}
                list={DocumentsContactsList}
                create={DocumentsContactsCreate}
                name="aboutFile"
                edit={DocumentsContactsEdit}
            />

            <Resource
                options={{ label: 'Партнерам', isMenuParent: true }}
                icon={Contacts}
                name="partners"
            />
            <Resource
                icon={Contacts}
                options={{ label: 'Партнеры', menuParent: 'partners' }}
                list={PartnersList}
                create={PartnersCreate}
                edit={PartnersEdit}
                name="sources"
            />
            <Resource
                icon={Contacts}
                options={{
                    label: 'Категории партнеров',
                    menuParent: 'partners',
                }}
                list={PartnersCategoriesList}
                create={PartnersCategoriesCreate}
                edit={PartnersCategoriesEdit}
                name="source-categories"
            />
            <Resource
                edit={ProductEdit}
                create={ProductCreate}
                icon={ShoppingCartIcon}
                options={{ label: 'Продукты', menuParent: 'products-parent' }}
                list={ProductList}
                name="product"
            />
            <Resource
                edit={ProductCategoryEdit}
                icon={ShoppingCartIcon}
                create={ProductCategoryCreate}
                options={{ label: 'Категории', menuParent: 'products-parent' }}
                list={ProductCategoryList}
                name="product-category"
            />
            <Resource
                icon={ShoppingCartIcon}
                options={{ label: 'Товарный сканер', isMenuParent: true }}
                name="commodity-parent"
            />
            <Resource
                icon={ShoppingCartIcon}
                options={{ label: 'Секторы', menuParent: 'commodity-parent' }}
                name="sectors"
                list={SectorList}
                create={SectorCreate}
                edit={SectorEdit}
            />
            <Resource
                icon={ShoppingCartIcon}
                options={{ label: 'Товары', menuParent: 'commodity-parent' }}
                name="commodities"
                list={CommodityList}
                create={CommodityCreate}
                edit={CommodityEdit}
            />
            <Resource
                options={{ label: 'Информация', isMenuParent: true }}
                name="publications-parent"
            />
            <Resource
                list={PublicationsList}
                create={PublicationsCreate}
                edit={PublicationEdit}
                options={{
                    label: 'Публикации',
                    menuParent: 'publications-parent',
                }}
                name="publications"
            />
            <Resource
                list={NewsList}
                create={NewsCreate}
                edit={NewsEdit}
                options={{
                    label: 'Новости',
                    menuParent: 'publications-parent',
                }}
                name="news"
            />
            <Resource
                options={{ label: 'Ресурсы', isMenuParent: true }}
                name="resource-parent"
            />
            <Resource
                list={ResourceCategoryList}
                create={ResourceCategoryCreate}
                edit={ResourceCategoryEdit}
                options={{ label: 'Категории', menuParent: 'resource-parent' }}
                name="resource-category"
            />
            <Resource
                list={ResourceCommodityList}
                create={ResourceCommodityCreate}
                edit={ResourceCommodityEdit}
                options={{ label: 'Ресурс', menuParent: 'resource-parent' }}
                name="commodity-resource"
            />
            <Resource
                list={CommodityCountryList}
                options={{ label: 'Страны', menuParent: 'resource-parent' }}
                name="commodity-country"
            />

            <Resource
                options={{ label: 'Cтратегии', isMenuParent: true }}
                name="strategies-parent"
            />
            <Resource
                name="strategies"
                list={StrategiesList}
                create={StrategyCreate}
                edit={StrategyEdit}
                options={{
                    label: 'Стратегии',
                    menuParent: 'strategies-parent',
                }}
            />
            <Resource
                name="strategy-tools"
                list={StrategiesToolsList}
                create={StrategyToolsCreate}
                edit={StrategyToolsEdit}
                options={{
                    label: (
                        <>
                            Инструменты
                            <br /> стратегии
                        </>
                    ),
                    menuParent: 'strategies-parent',
                }}
            />

            <Resource
                options={{ label: 'Модули', isMenuParent: true }}
                name="investmodules-parent"
            />
            <Resource
                name="investmodules"
                list={InvestmodulesList}
                create={InvestmoduleCreate}
                edit={InvestmoduleEdit}
                options={{
                    label: 'Модули',
                    menuParent: 'investmodules-parent',
                }}
            />
            <Resource
                name="investmodule-tools"
                list={InvestmodulesToolsList}
                create={InvestmoduleToolsCreate}
                edit={InvestmoduleToolsEdit}
                options={{
                    label: (
                        <>
                            Инструменты
                            <br /> модуля
                        </>
                    ),
                    menuParent: 'investmodules-parent',
                }}
            />

            <Resource
                options={{ label: 'Инвест идеи' }}
                icon={DocumentIcon}
                name="investideas"
                list={InvestIdeasList}
                edit={InvestIdeasEdit}
                create={InvestIdeasCreate}
            />

            <Resource
                options={{ label: 'Отзывы' }}
                icon={StarHalfIcon}
                name="reviews"
                list={ReviewsList}
                edit={ReviewEdit}
                create={ReviewCreate}
            />
        </Admin>
    );
}

export default App;
