import {
    Edit,
    SelectInput,
    SimpleForm,
    TextInput,
    DateInput,
    ImageInput,
} from 'ra-ui-materialui';
import { ImageField } from 'react-admin';

const PageTitle = () => {
    return <span>Отзыв</span>;
};

export const ReviewEdit = (props) => {
    return (
        <Edit title={<PageTitle />} mutationMode="optimistic" {...props}>
            <SimpleForm>
                <SelectInput
                    label="Рейтинг"
                    source="reviewRating"
                    choices={[
                        { id: '1', name: '1 звезда' },
                        { id: '2', name: '2 звезды' },
                        { id: '3', name: '3 звезды' },
                        { id: '4', name: '4 звезды' },
                        { id: '5', name: '5 звезд' },
                    ]}
                />

                <TextInput label="Текст отзыва" source="text" fullWidth />

                <TextInput label="Автор" source="author" />

                <DateInput label="Дата отзыва" source="date" />

                <ImageInput label="Логотип" source="logo" fullWidth>
                    <ImageField />
                </ImageInput>

                <SelectInput
                    label="Статус публикации"
                    source="publicationStatus"
                    choices={[
                        { id: 'draft', name: 'Черновик' },
                        { id: 'published', name: 'Опубликована' },
                    ]}
                />
            </SimpleForm>
        </Edit>
    );
};
