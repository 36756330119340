export default function errorDetector(
    codeOfError,
    statusText,
    id = '',
    message
) {
    const initialMessage = Array.isArray(message) ? message[0] : message;
    const messageError = initialMessage ?? statusText;

    if (codeOfError === '400' || codeOfError === 400) {
        return {
            icon: 'error',
            title: 'Неверный запрос',
            html: `Проверьте правильность введенных данных <br /><br/><br/> Описание ошибки: ${messageError} <br/><br/> Код ошибки: ${codeOfError}`,
        };
    } else if (codeOfError === '403' || codeOfError === 403) {
        return {
            icon: 'error',
            title: 'Мы вас не знаем',
            html: `Недостаточно прав на соверешение операции <br/><br/><br/> Описание ошибки: ${messageError} <br/><br/> Код ошибки: ${codeOfError}`,
        };
    } else if (codeOfError === '404' || codeOfError === 404) {
        return {
            icon: 'error',
            title: 'Мы не нашли что вы искали',
            html: `Елемент ${id} не найден <br/><br/><br/> Описание ошибки: ${messageError} <br/><br/> Код ошибки: ${codeOfError}`,
        };
    } else if (codeOfError === '500' || codeOfError === 500) {
        return {
            icon: 'error',
            title: 'Сервер отключен',
            html: `Попробуйте выполнить действие позже <br/><br/><br/> Описание ошибки: ${messageError} <br/><br/> Код ошибки: ${codeOfError}`,
        };
    } else if (codeOfError === '501' || codeOfError === 500) {
        return {
            icon: 'error',
            title: 'Сервер не понял действие',
            html: `Сервер не умеет такое обрабатывать  <br/><br/><br/> Описание ошибки: ${messageError} <br/><br/> Код ошибки: ${codeOfError}`,
        };
    } else {
        return {
            icon: 'error',
            title: 'Неизвестная ошибка',
            html: `Обратитесь к администратору<br/><br/><br/> Описание ошибки: ${messageError} <br/><br/> Код ошибки: ${codeOfError}`,
        };
    }
}
