import * as React from 'react';
import {
    DateInput,
    Edit,
    SelectInput,
    SimpleForm,
    TextInput,
    ImageInput,
} from 'ra-ui-materialui';
import { ImageField } from 'react-admin';
import { minLength, required } from 'ra-core';

import DraftEditor from '../../content-editor';
import dataProvider from '../../data-providers/data-provider';
import EditFormAutoSave from '../../helpers/editAndAutoSave';
import { SlugifyUrlFromTitle } from './create-view';

const urlValidation = [required(), minLength(2), validateURL];

const PostTitle = ({ record }) => {
    return <span>Документ {record ? `"${record.title}"` : ''}</span>;
};

export const NewsEdit = (props) => {
    return (
        <Edit title={<PostTitle />} mutationMode="optimistic" {...props}>
            <SimpleForm>
                <EditFormAutoSave waitInterval={5000} />
                <TextInput label="Название" source="title" fullWidth />
                <TextInput
                    fullWidth
                    label="Превью-текст"
                    multiline
                    source="preview"
                />
                <ImageInput
                    source="previewImage"
                    label="Превью-изображение"
                    fullWidth
                >
                    <ImageField />
                </ImageInput>
                <DraftEditor label="Контент" source="content" />
                <TextInput validate={urlValidation} label="URL" source="url" />
                <SlugifyUrlFromTitle />
                <SelectInput
                    label="Статус публикации"
                    source="status"
                    choices={[
                        { id: 'draft', name: 'Черновик' },
                        { id: 'published', name: 'Опубликован' },
                    ]}
                />
                <DateInput source="createdDate" label="Дата создания" />
            </SimpleForm>
        </Edit>
    );
};

async function validateURL(url, currentDocument) {
    const total = await dataProvider.getCount('document', { url });

    if (total >= 1 && url !== currentDocument.url) {
        return 'URL должен быть уникальным.';
    }

    return null;
}
