import { minValue, required } from 'ra-core';
import {
    FileField,
    FileInput,
    NumberInput,
    ReferenceInput,
    SelectInput,
    SimpleForm,
    TextInput,
    Edit,
} from 'ra-ui-materialui';

const PostTitle = ({ record }) => {
    return <span>Продукт {record ? `"${record.name}"` : ''}</span>;
};

export const ProductEdit = (props) => (
    <Edit mutationMode="optimistic" title={<PostTitle />} {...props}>
        <SimpleForm>
            <TextInput validate={required()} label="Название" source="name" />
            <NumberInput
                label="Цена в рублях"
                validate={[required(), minValue(0)]}
                source="price"
            />
            <TextInput
                label="Описание"
                multiline
                fullWidth
                source="description"
            />
            <FileInput label="Изображение" source="image">
                <FileField source="url" title="Изображение продукта" />
            </FileInput>
            <FileInput label="Документ" source="file">
                <FileField source="url" title="Продаваемый файл" />
            </FileInput>
            <SelectInput
                label="Тип"
                source="type"
                choices={[
                    { id: 'subscription', name: 'Подписка' },
                    { id: 'file', name: 'Документ' },
                ]}
            />
            <SelectInput
                label="Статус публикации"
                source="status"
                choices={[
                    { id: 'draft', name: 'Черновик' },
                    { id: 'published', name: 'Опубликован' },
                ]}
            />
            <ReferenceInput
                label="Категория"
                source="productCategory"
                reference="product-category"
            >
                <SelectInput optionText="name" />
            </ReferenceInput>
        </SimpleForm>
    </Edit>
);
