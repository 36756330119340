import { useEffect, useState } from 'react';
import { Edit, SelectInput, SimpleForm, TextInput } from 'ra-ui-materialui';

import DraftEditor from '../../content-editor';
import authAxios from '../../helpers/axios';

const PageTitle = ({ record }) => {
    return <span>Компания {record ? `"${record.name}"` : ''}</span>;
};

export const CompanyEdit = (props) => {
    const [industriesList, setIndustriesList] = useState([]);

    useEffect(() => {
        authAxios
            .get('/api/industries/published')
            .then((industriesList) => setIndustriesList(industriesList.data));
    }, []);

    return (
        <Edit title={<PageTitle />} mutationMode="optimistic" {...props}>
            <SimpleForm>
                <SelectInput
                    label="Статус публикации"
                    source="publicationStatus"
                    choices={[
                        { id: 'draft', name: 'Черновик' },
                        { id: 'published', name: 'Опубликована' },
                    ]}
                />
                <SelectInput
                    label="Отрасль"
                    source="industry"
                    choices={industriesList}
                />
                <SelectInput
                    label="Страна"
                    source="country"
                    choices={[
                        { id: 'США', name: 'США' },
                        { id: 'РФ', name: 'РФ' },
                    ]}
                />
                <TextInput label="Название" source="name" fullWidth />
                <TextInput
                    label="Превью-текст"
                    source="description"
                    fullWidth
                    multiline
                />
                <TextInput label="Рейтинг" source="rating" fullWidth />
                <DraftEditor label="контент" source="content" />
            </SimpleForm>
        </Edit>
    );
};
