import authAxios from './axios';

async function checkSourcePosition(categoryid) {
    const { data } = await authAxios.get('/api/sources');
    const currentSources = data.filter(
        (source) => source.category === categoryid
    );

    return currentSources.length + 1;
}

export default checkSourcePosition;
