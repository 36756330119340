import { Datagrid, List, TextField } from 'ra-ui-materialui';
import * as React from 'react';
import { ImageField } from 'react-admin';

export const CommodityCountryList = (props) => {
    return (
        <List {...props} exporter={false} title="Секторы" pagination={false}>
            <Datagrid rowClick="edit">
                <TextField label="название" source="name" />
                <ImageField label="флаг" source="icon" />
            </Datagrid>
        </List>
    );
};
