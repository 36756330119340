import styled from 'styled-components';

const BackBtn = styled.div`
    width: 70px;
    height: 40px;
    display: flex;
    padding: 0 10px;
    border-radius: 5px;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    font-family: 'Exo2';
    font-size: 18px;
    font-weight: 500;
    line-height: 22px;
    cursor: pointer;
    background-color: #3f51b5;
    color: #fff;
    box-shadow: 0 0 2px 0 #ccc;
    position: absolute;
    top: 100px;
    right: 40px;
    z-index: 1000;
`;

const BackButton = () => {
    const onBack = () => {
        window.history.back();
    };

    return <BackBtn onClick={onBack}>Назад</BackBtn>;
};

export default BackButton;
