export function setDateRange() {
    const allMounth = [
        'Январь',
        'Февраль',
        'Март',
        'Апрель',
        'Май',
        'Июнь',
        'Июль',
        'Август',
        'Сентябрь',
        'Октябрь',
        'Ноябрь',
        'Декабрь',
    ];
    const year = new Date().getFullYear();
    const month = new Date().getMonth();
    const result = [];

    allMounth.forEach((item, idx) => {
        if (idx >= month) {
            result.push({
                id: `${item} ${year - 1}`,
                name: `${item} ${year - 1}`,
            });
        }
        if (idx <= month + 2) {
            result.push({ id: `${item} ${year}`, name: `${item} ${year}` });
        }
    });

    return result;
}
