import { Datagrid, List, TextField } from 'ra-ui-materialui';
import { useState } from 'react';
import DragDropPosition from './dragDropPosition';
import styled from 'styled-components';
import { useRecordContext } from 'react-admin';

const Button = styled.button`
    border: 0;
    margin: 40px 0 0;
    padding: 20px 0;
    font-family: 'Exo2';
    font-size: 24px;
    font-weight: 600;
    cursor: pointer;
`;

const CustomField = (props) => {
    const { source } = props;
    const record = useRecordContext(props);
    return <span>{record[source] + 1}</span>;
};

export const RatingIndustryList = (props) => {
    const [draggable, setDraggable] = useState(false);

    return (
        <>
            {!draggable ? (
                <List
                    {...props}
                    exporter={false}
                    pagination={false}
                    title="Рейтинг отраслей"
                >
                    <Datagrid rowClick="edit">
                        <TextField label="id" source="id" />
                        <TextField label="Название" source="name" />
                        <CustomField label="Позиция" source="position" />
                        <TextField label="Страна" source="country" />
                    </Datagrid>
                </List>
            ) : (
                <DragDropPosition />
            )}
            <Button onClick={() => setDraggable(!draggable)}>
                {draggable
                    ? 'Вернуться к рейтингу индустрий'
                    : 'Перейти к редактированию позиции'}
            </Button>
        </>
    );
};
