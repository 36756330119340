import { Create, SimpleForm, TextInput } from 'ra-ui-materialui';
import * as React from 'react';

export const ResourceCategoryCreate = (props) => {
    return (
        <Create {...props}>
            <SimpleForm>
                <TextInput label="Название" fullWidth source="name" />
            </SimpleForm>
        </Create>
    );
};
