import { required } from 'ra-core';
import { SimpleForm, TextInput, Edit } from 'ra-ui-materialui';

export const PartnersCategoriesEdit = (props) => (
    <Edit {...props}>
        <SimpleForm>
            <TextInput
                validate={required()}
                source="title"
                label={`Название`}
            />
            <TextInput
                validate={required()}
                source="description"
                label={`Описание`}
            />
        </SimpleForm>
    </Edit>
);
