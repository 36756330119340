import React, { useEffect, useState } from 'react';
import ReactDragListView from 'react-drag-listview';
import styled from 'styled-components';
import Swal from 'sweetalert2';
import errorDetector from '../../../helpers/errorDetector';
import authAxios from '../../../helpers/axios';

const MainWrapper = styled.div`
    width: 60%;
    margin: 0 auto;
    position: relative;
`;

const MainTitle = styled.div`
    max-width: 100%;
    margin-top: 50px;
    display: flex;
    justify-content: space-between;
    padding: 0 80px;
    align-items: center;
`;

const Ul = styled.ul`
    width: 100%;
    margin: 0;
    list-style: none;
    padding: 0;
`;

const Li = styled.li`
    box-shadow: 0 0 1px 0 #000;
`;

const DragWrapper = styled.a`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: 0 0 1px 0 #000;
    padding: 5px 70px;
    position: relative;
`;

const LiName = styled.p`
    width: 200px;
`;

const LiOldNumber = styled.p`
    width: 40px;
    text-align: center;
    position: relative;
    left: -40px;
`;

const LiNewNumber = styled.p`
    width: 40px;
    text-align: center;
    position: relative;
    left: -130px;
`;

const ButtonWrapper = styled.div`
    padding: 40px 0 0;
`;

const Button = styled.button`
    border: 0;
    border-radius: 4px;
    padding: 10px 20px;
    margin-right: 20px;
    background-color: #0acdec;
    cursor: pointer;
`;

const LinkToDrag = styled.div`
    line-height: 50px;
    font-weight: bold;
    position: absolute;
    left: 20px;
    top: 50%;
    transform: translate3d(0, -50%, 0);
    cursor: pointer;

    &:hover {
        color: blue;
    }
`;

const WaitBlock = styled.div`
    position: fixed;
    width: 100vw;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    top: 0;
    left: 0;
    background-color: rgba(255, 255, 255, 0.95);
`;

export default function DragAndDropPositionSource() {
    const [allCategories, setAllCategories] = useState([]);
    const [allSources, setAllSources] = useState([]);
    const [initialSources, setInitialSources] = useState([]);
    const [initialCategory, setInitialCategory] = useState('');
    const [startLoad, setStartLoad] = useState(false);

    useEffect(() => {
        async function loadData() {
            const { data } = await authAxios.get('/api/source-categories');
            const { data: sources } = await authAxios.get('/api/sources');
            setAllCategories(data);
            setAllSources(sources);
            if (data[0]) {
                setInitialCategory(data[0].id);
            }
        }

        loadData();
    }, []);

    useEffect(() => {
        if (initialCategory) {
            const currentSources = allSources.filter(
                (source) => source.category === initialCategory
            );
            currentSources.sort((a, b) => a.position - b.position);
            setInitialSources(currentSources);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [initialCategory]);

    const handleChange = (e) => {
        e.preventDefault();

        setInitialCategory(e.target.value);
    };

    const dragProps = {
        onDragEnd(fromIndex, toIndex) {
            const dataForDrag = [...initialSources];
            const item = dataForDrag.splice(fromIndex, 1)[0];
            dataForDrag.splice(toIndex, 0, item);
            setInitialSources(dataForDrag);
        },
        nodeSelector: 'li',
        handleSelector: 'a',
    };

    const onSubmit = async () => {
        const dataForAxios = initialSources.map((item, index) => {
            return { ...item, position: index + 1 };
        });
        try {
            setStartLoad(true);
            for (const item of dataForAxios) {
                await authAxios.patch(`/api/sources/${item.id}`, {
                    title: item.title,
                    position: item.position,
                    link: item.link,
                    status: item.status,
                    description: item.description,
                    category: item.category,
                    logo: item.logo,
                });
            }
            setStartLoad(false);
            Swal.fire({
                title: 'Спасибо',
                html: 'Мы все получили!',
                icon: 'success',
            });
        } catch (e) {
            return Swal.fire(
                errorDetector(
                    e.response.status,
                    e.response.statusText,
                    'sources',
                    e.response.message
                )
            );
        }
    };
    return (
        <>
            {startLoad && (
                <WaitBlock>
                    <h1>Идет загрузка</h1>
                    <h1>Подождите</h1>
                </WaitBlock>
            )}
            <MainWrapper>
                <MainTitle>
                    <p>Имя</p>
                    <p>Новая Позиция</p>
                    <p>Старая Позиция</p>
                </MainTitle>
                <ReactDragListView {...dragProps}>
                    <Ul>
                        {initialSources.map((item, index) => {
                            return (
                                <Li key={item.name}>
                                    <DragWrapper>
                                        <LinkToDrag>|||</LinkToDrag>
                                        <LiName
                                            style={{ margin: '0 0 0 10px' }}
                                        >
                                            {item.title}
                                        </LiName>
                                        <LiNewNumber>{index + 1}</LiNewNumber>
                                        <LiOldNumber>
                                            {item.position}
                                        </LiOldNumber>
                                    </DragWrapper>
                                </Li>
                            );
                        })}
                    </Ul>
                </ReactDragListView>
                <ButtonWrapper>
                    <Button onClick={onSubmit}>Сохранить</Button>
                    <select onChange={(e) => handleChange(e)}>
                        {allCategories.map((category) => (
                            <option value={category.id}>
                                {category.title}
                            </option>
                        ))}
                    </select>
                </ButtonWrapper>
            </MainWrapper>
        </>
    );
}
